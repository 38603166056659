import React from 'react';

import { header_container } from 'src/styles/Common.module.scss';

/**
 * Component ContainerHead has max width and uses for header section in main page and header
 * @class ContainerHead
 * @memberof module:layout
 * @property {component} children
 * @example
 * return (
 *   <ContainerHead>{children}</ContainerHead>
 * )
 */
const ContainerHead = ({ children }) => <div className={header_container}>{children}</div>;

export default ContainerHead;
