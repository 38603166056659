/** @module utils/goTop */

/**
 * scroll page top
 * @constant
 * @type {function}
 */
export default () => {
    window.scrollTo(0, 0);
};
