import React from 'react';

import animateScrollTo from 'animated-scroll-to';
import { btn_up } from 'src/styles/Btns.module.scss';
import { ButtonUpArrow } from 'src/components/layouts/base/images';

const ButtonUp = () => {
    return (
        <button className={btn_up} onClick={() => animateScrollTo(0)}>
            <ButtonUpArrow style={{ width: '25px', height: '25px' }} />
        </button>
    );
};

export default ButtonUp;
