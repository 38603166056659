import React from 'react';

import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { LOCAL_STORAGE } from 'src/config/constants';
import { logIn, logOut, updateGoogleCredentials, reLogIn } from 'src/reducers/account/auth';

const ONE_MINUTE = 60 * 1000;
const FIVE_MINUTES = 5 * ONE_MINUTE;
let last_refreshing_expires_at = null;

const getRefreshDeadline = (expires_at) => expires_at - Date.now() - FIVE_MINUTES;
const consoleLogRefreshing = (refreshDeadline) =>
    console.log(`Refreshing credentials in ${(refreshDeadline / ONE_MINUTE).toString()} minutes`);

const reloadAuthToken =
    (response, logOut, updateGoogleCredentials) =>
    (refresh = true, onlySetTimeout = false) => {
        response.reloadAuthResponse().then(
            // success handler.
            (authResponse) => {
                /*    console.log(authResponse); */
                last_refreshing_expires_at = authResponse.expires_at;
                if (onlySetTimeout === false) {
                    updateGoogleCredentials({
                        ...response,
                        tokenObj: authResponse,
                    });
                }
                if (refresh)
                    setRefreshTimeout(
                        reloadAuthToken(response, logOut, updateGoogleCredentials),
                        authResponse.expires_at,
                    );
            },
            // fail handler.
            logOut,
        );
    };

const setRefreshTimeout = (callback, expires_at) => {
    // Either 5 minutes before the deadline, or 5 minutes from now. This
    // should prevent thrashing while keeping the credentials fresh.
    const refreshDeadline = getRefreshDeadline(expires_at);
    consoleLogRefreshing(refreshDeadline);
    setTimeout(() => {
        const is_logged = localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS);
        if (is_logged) callback();
    }, refreshDeadline);
};

const onSuccess = (is_signed_in, onLogIn, logOut, updateGoogleCredentials) => (response) => {
    if (!is_signed_in || last_refreshing_expires_at === null) {
        const reloadAuthToken_callback = reloadAuthToken(response, logOut, updateGoogleCredentials);
        onLogIn(response);
        reloadAuthToken_callback(true);
        window.onfocus = () => {
            const is_logged = localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS);
            if (is_logged) {
                const refreshDeadline = getRefreshDeadline(last_refreshing_expires_at);
                if (refreshDeadline <= 0) reloadAuthToken_callback(false);
                else consoleLogRefreshing(refreshDeadline);
            }
        };
    }
};

const onFailure = (response) => {
    /* console.log(response); */
};

const onLogoutSuccess = (logOut) => (response) => {
    logOut(response);
};

const GoogleBtnAuthWrapper = React.memo(
    ({ children, logIn, logOut, reLogIn, updateGoogleCredentials, is_signed_in = false, is_visible_force = false }) => {
        const is_logged = localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS);
        // You need this for starting google token refreshing
        const is_visible = (is_logged && is_signed_in) || (!is_logged && !is_signed_in);

        const onLogIn = (response) => {
            if (is_signed_in && is_visible_force) reLogIn({ ...response });
            else logIn({ ...response });
        };

        const isSignedIn = is_signed_in && !is_visible_force;

        return (
            <>
                {is_visible && (
                    <div style={isSignedIn ? { visibility: 'hidden', width: 0 } : {}}>
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            render={(renderProps) => {
                                return React.Children.map(children, (child) => React.cloneElement(child, renderProps));
                            }}
                            onSuccess={onSuccess(isSignedIn, onLogIn, logOut, updateGoogleCredentials)}
                            onFailure={onFailure}
                            isSignedIn={isSignedIn}
                            onLogoutSuccess={onLogoutSuccess(logOut)}
                            scope={process.env.REACT_APP_GOOGLE_SCOPE}
                            approvalPrompt='force'
                            prompt='consent'
                        />
                    </div>
                )}{' '}
            </>
        );
    },
);

export default connect(
    ({ auth: { is_signed_in } }) => ({
        is_signed_in,
    }),
    { logIn, logOut, updateGoogleCredentials, reLogIn },
)(GoogleBtnAuthWrapper);
