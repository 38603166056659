import React from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Nav, NavItem } from 'reactstrap';
import { PAGES_IDS } from 'src/config/constants';
import { getSolutions } from 'src/reducers/lending/reducers';
import { active_page } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';

export const Navigation = React.memo(({ pages, lang, class_navbar_link, className = '' }) => {
    return (
        <Nav className={`mr-auto ${className}`} navbar>
            <NavItem>
                <NavHashLink className={class_navbar_link} smooth to={`${createPath([lang])}#solutions`}>
                    Solutions
                </NavHashLink>
            </NavItem>
            {Object.keys(pages)
                .filter((page_id) => [PAGES_IDS.OFFER,PAGES_IDS.TRAINING,PAGES_IDS.CONTACT, PAGES_IDS.FAQ].includes(page_id)) //  PAGES_IDS.PRICING
                .map((page_id) => {
                    const { title, slug } = pages[page_id];
                    return (
                        <NavItem key={page_id}>
                            <NavLink
                                className={class_navbar_link}
                                activeClassName={active_page}
                                to={createPath([lang, slug[lang]])}>
                                {title}
                            </NavLink>
                        </NavItem>
                    );
                })}
        </Nav>
    );
});

export default connect({ getSolutions })(Navigation);
