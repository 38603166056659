import React from 'react';

import { content_container } from 'src/styles/Common.module.scss';

/**
 * Component Container has max width and uses for some sections in pages
 * @class Container
 * @memberof module:layout
 * @property {component} children
 * @example
 * return (
 *   <Container>{children}</Container>
 * )
 */
export default React.memo(({ children }) => <div className={content_container}>{children}</div>);
