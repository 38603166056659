/** @module reducers/account */

import axios from 'axios';
import update from 'immutability-helper';
import { REDUCERS_NAMES, HEADERS, LOCAL_STORAGE } from 'src/config/constants';
import {
    get_messages,
    get_accounts,
    get_subscription_data,
    get_report_pages,
    get_reports,
    update_report_form,
    create_report,
    get_report_by_id,
    delete_report_by_id,
    reset_mesages,
    reset_accounts,
    reset_reports,
    reset_reports_pages,
    reset_create_report,
    reset_subscription,
    get_report_pages_in_background,
    reset_update_report_form,
    refresh_token,
    post_check_account,
    post_status_monthly_report,
    get_report_page_template,
    reset_report_page_template,
    report_email,
    report_email_new,
    save_for_email_new,
    get_report_pdf,
    reset_report_pdf,
    add_report_page_mark,
    grant_google_mcc_access,
    active_pdf,
    remove_pdf_uri,
    send_pdf,
    set_menu,
    active_loader,
    change_width,
    disable_loader,
    disable_pdf,
    set_list_report,
    set_said_menu,
    set_id,
    disable_loader_pdf,
    get_all_reports_data,
    toggle_top_menu,
    writing_data_for_dashboard_pdf,
    toggle_popup_contact
} from 'src/reducers/account/actions';
import {
    baseReducer,
    actionHandlerOnce,
    actionHandler,
    baseInitialState,
    actionHandlerReset,
    baseInitialStateDataObject,
    action_do_nothing,
    rejectedState,
    pendingState,
    fulfilledState,
} from 'src/reducers/base';
import { savePDF, reportFileNameFormat } from 'src/utils/savePDF';

const accountsBaseInitialState = {
    ...baseInitialState,
    is_loading_checking: false,
    checked_accounts_succesfully: [],
    checked_accounts_unsuccessfully: [],
};

const reportTemplateBaseInitialState = {
    ...baseInitialStateDataObject,
    is_loading_mark: false,
};

/**
 * MESSAGES ACTION HANDLER
 * @type {function}
 */
export const getMessages = actionHandlerOnce(REDUCERS_NAMES.ACCOUNT_MESSAGES, get_messages, true);

/**
 * RESET MESSAGES ACTION HANDLER
 * @type {function}
 */
export const resetMessages = actionHandlerReset(reset_mesages);

/**
 * ACCOUNTS ACTION HANDLER
 * @type {function}
 */
export const getAccounts = actionHandlerOnce(REDUCERS_NAMES.ACCOUNT_ACCOUNTS, get_accounts, true);

/**
 * CHECK GOOGLE ACCOUNT ACTION HANDLER
 * @type {function}
 */
export const checkAccount = actionHandler(post_check_account, true);

/**
 * CHANGE STATUS OF MONTHLY REPORT IN GOOGLE ACCOUNT ACTION HANDLER
 * @type {function}
 */
export const changeStatusMonthlyReport = actionHandler(post_status_monthly_report, true);

/**
 * RESET ACCOUNTS ACTION HANDLER
 * @type {function}
 */
export const resetAccounts = actionHandlerReset(reset_accounts, accountsBaseInitialState);

/**
 * SUBSCRIPTION DATA ACTION HANDLER
 * @type {function}
 */
export const getSubscriptionData = actionHandlerOnce(REDUCERS_NAMES.ACCOUNT_SUBSCRIPTION_DATA, get_subscription_data);

/**
 * RESET SUBSCRIPTION DATA ACTION HANDLER
 * @type {function}
 */
export const resetSubscriptionData = actionHandlerReset(reset_subscription, baseInitialStateDataObject);

/**
 * REPORTS LIST ACTION HANDLER
 * @type {function}
 */
export const getReports = actionHandlerOnce(REDUCERS_NAMES.REPORT_LIST, get_reports, true);

/**
 * RESET REPORTS LIST ACTION HANDLER
 * @type {function}
 */
export const resetReports = actionHandlerReset(reset_reports, baseInitialStateDataObject);

/**
 * REPORT BY ID ACTION HANDLER
 * @type {function}
 */
export const getReportById = actionHandler(get_report_by_id, true);

/**
 * REPORT BY ID ACTION HANDLER
 * @type {function}
 */
export const deleteReportById = actionHandler(delete_report_by_id, true);

/**
 * REPORTS PAGES ACTION HANDLER
 * @type {function}
 */
export const getReportPages = actionHandler(get_report_pages, true);

/**
 * REPORTS PAGES ACTION HANDLER
 * @type {function}
 */
export const getReportByIdInBackground = actionHandler(get_report_pages_in_background, true);

/**
 * RESET REPORTS PAGES ACTION HANDLER
 * @type {function}
 */
export const resetReportsPages = actionHandlerReset(reset_reports_pages, baseInitialStateDataObject);

/**
 * REPORT CREATE ACTION HANDLER
 * @type {function}
 */
export const createReport = actionHandler(create_report, true);

/**
 * RESET REPORT CREATE ACTION HANDLER
 * @type {function}
 */
export const resetCreateReport = actionHandlerReset(reset_create_report, baseInitialStateDataObject);

/**
 * REPORTS FORM ACTION HANDLER
 * @type {function}
 */
export const updateReportForm = actionHandler(update_report_form);

/**
 * RESET REPORTS FORM ACTION HANDLER
 * @type {function}
 */
export const resetUpdateReportForm = actionHandlerReset(reset_update_report_form, baseInitialStateDataObject);

/**
 * REFRESH TOKEN ACTION HANDLER
 * @type {function}
 */
export const refreshToken = actionHandlerOnce(REDUCERS_NAMES.REFRESH_TOKEN, refresh_token, true);

/**
 *GET REPORT PAGE TEMPLATE HANDLER
 * @type {function}
 */
export const getReportPageTemplate = actionHandler(get_report_page_template, true);

/**
 * ADD REPORT PAGE MARK HANDLER
 * @type {function}
 */
export const addReportPageMark = actionHandler(add_report_page_mark, true);

/**
 * GRANT GOOGLE MCC ACCESS HANDLER
 * @type {function}
 */
export const grantGoogleMCCAccess = actionHandler(grant_google_mcc_access, true);

/**
 * RESET REPORT PAGE TEMPLATE HANDLER
 * @type {function}
 */
export const resetReportPageTemplate = actionHandlerReset(reset_report_page_template, reportTemplateBaseInitialState);

/**
 * POST REPORT EMAIL HANDLER
 * @type {function}
 */
export const postReportEmail = actionHandler(report_email, true);

/**
 * PUT REPORT EMAIL HANDLER(new with front pdf generation)
 * @type {function}
 */
 export const putReportEmail = actionHandler(report_email_new, true);

/**
 * save to ram report for future send
 * @type {function}
 */
 export const saveReportEmail = actionHandler(save_for_email_new, true);

/**
 * DOWNLOAD REPORT PDF
 * @type {function}
 */
export const getReportPDF =
    (args = {}) =>
        (dispatch, getState) => {
            const { id, account, period, account_type } = args;
            const {
                [REDUCERS_NAMES.REPORT_PDFS]: { data },
                [REDUCERS_NAMES.LANG]: { lang },
            } = getState();
            const [is_fetched, is_loading] =
                lang in data && id in data[lang] ? [data[lang][id].is_fetched, data[lang][id].is_loading] : [false, false];
            const file_name = reportFileNameFormat({ id, account_id: account.id, period, account_type, lang });
            if (is_fetched) {
                savePDF({ file_name, data: data[lang][id].data });
            } else if (localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS) && !is_fetched && !is_loading) {
                dispatch(get_report_pdf({ id, file_name, lang }));
            }
            return dispatch(action_do_nothing(get_report_pdf));
        };

/**
 * RESET REPORT PDFS
 * @type {function}
 */
export const resetReportPDF = actionHandlerReset(reset_report_pdf, baseInitialStateDataObject);

/**
 * MESSAGES Reducer
 */
export const account_messages = baseReducer(get_messages);

/**
 * ACCOUNTS Reducer
 */
export const account_accounts = baseReducer(get_accounts, accountsBaseInitialState, {
    [post_check_account.fulfilled]: (state, action) => {
        const { checked_accounts_succesfully, checked_accounts_unsuccessfully } = state;
        const {
            payload: {
                data: { account_id, has_campaigns },
            },
        } = action;
        if (action.payload.isAxiosError) {
            return {
                ...state,
                is_loading_checking: false,
            };
        }
        return {
            ...state,
            is_loading_checking: false,
            checked_accounts_succesfully:
                has_campaigns && account_id
                    ? [...checked_accounts_succesfully, account_id]
                    : checked_accounts_succesfully,
            checked_accounts_unsuccessfully:
                !has_campaigns && account_id
                    ? [...checked_accounts_unsuccessfully, account_id]
                    : checked_accounts_unsuccessfully,
        };
    },
    [post_check_account.pending]: (state) => {
        return {
            ...state,
            is_loading_checking: true,
        };
    },
});

/**
 * SUBSCRIPTION Reducer
 */
export const account_subscription_data = baseReducer(get_subscription_data, baseInitialStateDataObject);

const update_full_report = (state, { payload: { isAxiosError, data } }) => {
    if (isAxiosError || data === undefined) return state;
    const is_child_of_group = !!data?.group_id;
    if (is_child_of_group) {
        return update(state, {
            data: {
                groups: {
                    $set: state.data.groups.map((group) =>
                        group.id * 1 === data.group_id * 1
                            ? update(group, {
                                reports: {
                                    $set: group.reports.map((report) =>
                                        report.id * 1 === data.id * 1 ? data : report,
                                    ),
                                },
                            })
                            : group,
                    ),
                },
            },
        });
    }
    if (Array.isArray(state.data.items)) {
        return update(state, {
            data: {
                items: {
                    $set: state.data.items.map((report) => (report.id * 1 === data.id * 1 ? data : report)),
                },
            },
        });
    }
    return state;
};

const update_group = (state, reports_group_id, update_obj) => {
    return update(state, {
        data: {
            groups: {
                $set: state.data.groups.map((group) =>
                    +group.id === +reports_group_id ? update(group, update_obj(group)) : group,
                ),
            },
        },
    });
};

/**
 * REPORTS LIST Reducer
 */
export const reports_list = baseReducer(get_reports, baseInitialStateDataObject, {
    [get_report_by_id.fulfilled]: update_full_report,
    [delete_report_by_id.fulfilled]: (state, { payload: { isAxiosError, data } }) => {
        if (isAxiosError || data === undefined) return state;
        const is_child_of_group = !!data?.group_id;
        if (is_child_of_group) {
            return update_group(state, data.group_id, (group) => ({
                reports: {
                    $set: group.reports.filter((report) => report.id * 1 !== data.id * 1),
                },
            }));
        }
        return update(state, {
            data: { items: { $set: state.data.items.filter((report) => report.id !== data.id) } },
        });
    },
    [report_email.fulfilled]: update_full_report,
    [post_status_monthly_report.fulfilled]: (state, { payload: { isAxiosError, data }, meta }) => {
        if (isAxiosError || data === undefined || !Array.isArray(state?.data?.groups)) return state;
        const { is_active_monthly_report: is_active } = data;
        const { account_id } = meta;
        return update(state, {
            data: {
                groups: {
                    $set: state.data.groups.map((group) =>
                        group.account.id * 1 === account_id * 1
                            ? update(group, {
                                is_active: {
                                    $set: is_active,
                                },
                            })
                            : group,
                    ),
                },
            },
        });
    },
    [grant_google_mcc_access.pending]: (state, { meta }) => {
        if (!Array.isArray(state?.data?.groups)) return state;
        const { reports_group_id } = meta;
        return update_group(state, reports_group_id, () => ({
            is_mcc_access_access: {
                $set: true,
            },
        }));
    },
    [grant_google_mcc_access.rejected]: (state, { meta }) => {
        if (!Array.isArray(state?.data?.groups)) return state;
        const { reports_group_id } = meta;
        return update_group(state, reports_group_id, () => ({
            is_mcc_access_access: {
                $set: false,
            },
        }));
    },
    [grant_google_mcc_access.fulfilled]: (state, { payload: { isAxiosError, data }, meta }) => {
        if (isAxiosError || data === undefined || !Array.isArray(state?.data?.groups)) return state;
        const { reports_group_id } = meta;
        const { has_mcc_access } = data;
        return update_group(state, reports_group_id, () => ({
            has_mcc_access: {
                $set: has_mcc_access,
            },
            is_mcc_access_access: {
                $set: false,
            },
        }));
    },
});

/**
 * REPORTS PAGES Reducer
 */
export const report_pages = baseReducer(get_report_pages, baseInitialStateDataObject, {
    [get_report_pages_in_background.fulfilled]: (state, action) => {
        return action.payload.isAxiosError || action.payload.data === undefined
            ? state
            : update(state, { data: { $set: action.payload.data } });
    },
    [report_email.fulfilled]: (state, action) => {
        return action.payload.isAxiosError || action.payload.data === undefined
            ? state
            : update(state, { data: { $merge: action.payload.data } });
    },
});

/**
 * REPORTS FORM Reducer
 */
export const report_form = baseReducer(update_report_form, baseInitialState, {
    [post_status_monthly_report.fulfilled]: (state, { payload: { isAxiosError, data } }) => {
        if (isAxiosError || data === undefined) return state;
        const { is_active_monthly_report } = data;
        return {
            ...state,
            data: {
                ...state.data,
                is_active_monthly_report,
            },
        };
    },
});

/**
 * REPORT CREATE Reducer
 */
export const report_create = baseReducer(create_report, baseInitialStateDataObject);

/**
 * REFRESH TOKEN Reducer
 */
export const token = baseReducer(refresh_token, baseInitialState, {
    [refresh_token.fulfilled]: (state, action) => {
        if (action.payload.isAxiosError || action.payload.data === undefined) {
            return {
                ...state,
                ...rejectedState(),
            };
        }
        if (action.payload.data?.token?.access) {
            localStorage.setItem(LOCAL_STORAGE.TOKEN_ACCESS, action.payload.data.token.access);
            axios.defaults.headers.common = {
                ...axios.defaults.headers.common,
                [HEADERS.AUTHORIZATION]: `Bearer ${localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS)}`,
            };
        }
        return {
            ...state,
            ...fulfilledState,
            data: action.payload.data,
        };
    },
});

/**
 * REPORT TEMPLATES Reducer
 */
export const report_templates = baseReducer(get_report_page_template, reportTemplateBaseInitialState, {
    [get_report_page_template.fulfilled]: (state, action) => {
        if (!action.payload.isAxiosError && typeof action?.payload?.data === 'object') {
            const { id, slug, template, mark } = action.payload.data;
            return {
                ...state,
                ...fulfilledState,
                data: {
                    ...state.data,
                    id,
                    slug,
                    [id]: {
                        ...(state.data[id] || {}),
                        [slug]: { template, mark },
                    },
                },
            };
        }
        return {
            ...state,
            ...rejectedState(),
        };
    },
    [add_report_page_mark.fulfilled]: (state, action) => {
        if (!action.payload.isAxiosError && typeof action?.payload?.data === 'object') {
            const {
                payload: { data: mark },
                meta: { id, slug },
            } = action;
            const id_data = state.data[id] || {};
            const slug_data = id_data[slug] || {};
            return {
                ...state,
                data: {
                    ...state.data,
                    [id]: {
                        ...id_data,
                        [slug]: { ...slug_data, mark },
                    },
                },
            };
        }
        return {
            ...state,
            is_loading_mark: false,
        };
    },
    [add_report_page_mark.pending]: (state) => {
        return {
            ...state,
            is_loading_mark: true,
        };
    },
    [add_report_page_mark.rejected]: (state) => {
        return {
            ...state,
            is_loading_mark: false,
        };
    },
});

const mutate_report_pdfs = (state, changes, lang, id) => ({
    ...state,
    data: {
        ...state.data,
        [lang]: {
            ...(lang in state.data && state.data[lang] ? state.data[lang] : {}),
            [id]: {
                ...(lang in state.data && state.data[lang] && id in state.data[lang] && state.data[lang][id]
                    ? state.data[lang][id]
                    : {}),
                ...changes,
            },
        },
    },
});

/**
 * REPORT PDFs Reducer
 */
export const report_pdfs = baseReducer(get_report_pdf, baseInitialStateDataObject, {
    [get_report_pdf.fulfilled]: (state, action) => {
        const {
            payload: { data },
            meta: { file_name, id, lang },
        } = action;
        if (action.payload.isAxiosError) {
            return mutate_report_pdfs(state, rejectedState(), lang, id);
        }
        savePDF({ file_name, data });
        return mutate_report_pdfs(state, { ...fulfilledState, data }, lang, id);
    },
    [get_report_pdf.pending]: (state, action) => {
        const {
            meta: { id, lang },
        } = action;
        return mutate_report_pdfs(state, pendingState, lang, id);
    },
    [get_report_pdf.rejected]: (state, action) => {
        const {
            meta: { id, lang },
        } = action;
        return mutate_report_pdfs(state, rejectedState(), lang, id);
    },
});


const initialState = {
    pdfReport: false,
    dasbordPdf: false,
    idReport: null,
    width: 1920,
    loader: true,
    loaderPDF: false,
    listReports: [],
    selectMenu: { name: '', index: 0 },
    saidMenu: true,
    score: [],
    toggleTopMenu: false,
    data: [],
    error: false,
    dataReportDashboard:{},
    popupContact: false
};

export const get_list_reports = baseReducer(get_all_reports_data, initialState, {
    [get_all_reports_data.fulfilled.type]: (state, action) => {
       return {
            ...state,
            data: action.payload 
        }
    },
    [get_all_reports_data.rejected]: (state) => {
       return {
            ...state,
            error: true
        }

    },
    
})

export const pdfReport = baseReducer([
    set_menu,
    active_loader,
    change_width,
    disable_loader,
    disable_pdf,
    set_list_report,
    set_said_menu,
    active_pdf,
    send_pdf,
    remove_pdf_uri,
    set_id,
    disable_loader_pdf,
    toggle_top_menu,
    writing_data_for_dashboard_pdf,
    toggle_popup_contact], initialState, {
    activePDF: (state) => {
        return {
            ...state,
            pdfReport: true,
            toSend:false
        }
    },
    sendPDF: (state,action) => {
        return {
            ...state,
            pdfReport: true,
            toSend: true,
            email: action.payload.email,
            account_name: action.payload.account_name
        }
    },
    savePDFURI: (state,action) => {
        return {
            ...state,
            dataURI: action.payload.dataUri,
            name:action.payload.name,
            account_name:action.payload.account_name,
        }
    },
    removeURI: (state,action) => {
        return {
            ...state,
            dataURI: null,
        }
    },
    disablePDF: (state) => {
        return {
            ...state,
            pdfReport: false
        }
    },
    activeDashbord: (state) => {
        return {
            ...state,
            dasbordPdf: true
        }
    },
    disableDashbord: (state) => {
        return {
            ...state,
            dasbordPdf: false
        }
    },
    activeLoader: (state) => {
        return {
            ...state,
            loader: true
        }
    },
    disableLoader: (state) => {
        return {
            ...state,
            loader: false
        }
    },
    activeLoaderPDF: (state) => {
        return {
            ...state,
            loaderPDF: true
        }
    },
    disableLoaderPDF: (state) => {
        return {
            ...state,
            loaderPDF: false
        }
    },
    enableLoaderEmail: (state) => {
        return {
            ...state,
            loaderEmail: true
        }
    },
    disableLoaderEmail: (state) => {
        return {
            ...state,
            loaderEmail: false
        }
    },
    setMenu: (state, action) => {
        return {
            ...state,
            selectMenu: action.payload
        }
    },
    toggleTopMenu: (state, action) => {
        return {
            ...state,
            toggleTopMenu: !action.payload ? !state.toggleTopMenu : action.payload
        }
    },
    setSaidMenu: (state, action) => {
        return {
            ...state,
            saidMenu: action.payload
        }
    },
    setID: (state, action) => {
        return {
            ...state,
            idReport: action.payload
        }
    },
    changeWidth: (state, action) => {
        return {
            ...state,
            width: action.payload
        }
    },
    setListReport: (state, action) => {
        return {
            ...state,
            listReports: action.payload
        }
    },
    setDataReportForPDF: (state, action) => {
        return {
            ...state,
            dataReportDashboard: action.payload
        }
    },
    toggleContact: (state, action) => {
        return {
            ...state,
            popupContact: action.payload
        }
    }
});