import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PageHeader from 'src/components/layouts/base/PageHeader';
import { error_page_description_text, error_page_title_text } from 'src/config/translation';
import goTop from 'src/utils/goTop';

/**
 * Component ErrorPage
 *
 * @class
 * @memberof module:sections
 * @example
 * return (
 *   <ErrorPage />
 * )
 */
class ErrorPage extends PureComponent {
    componentDidMount() {
        goTop();
    }

    render() {
        const { background, lang } = this.props;
        return (
            <>
                <PageHeader
                    title={error_page_title_text[lang]}
                    description={error_page_description_text[lang]}
                    bg_src={background.secondary_page_header?.image}
                />
            </>
        );
    }
}

export default connect(({ main: { background }, lang: { lang } }) => ({
    lang,
    background,
}))(ErrorPage);
