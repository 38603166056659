/** @module utils/pathManager */

import { memoize } from 'lodash';

/**
 * removes / at both sides
 * @function
 * @param {string} path path what you need clear from / in the beginning
 * @returns {string}
 */
export const trimSlashes = memoize((path) => replaceDubleSlashes(path.replace(/^\/+|\/+$/g, '')));

const replaceDubleSlashes = memoize((path) => path.replace(/\/{2,}/g, '/'));

/**
 * wrap path with / at end sides
 * @function
 * @param {string[]} parts
 * @returns {string}
 */
export const createPath = (() => {
    const cache = {};
    return (parts) => {
        const cache_key = parts.join('').replace(/\/{1,}/g, '');
        if (cache_key.length === 0) return cache_key;
        if (!(cache_key in cache)) {
            let trimed_parts = parts.map((part) => trimSlashes(part)).join('/');
            if (trimed_parts.length > 0) trimed_parts = `/${trimSlashes(trimed_parts)}/`;
            cache[cache_key] = trimed_parts;
        }
        return cache[cache_key];
    };
})();
