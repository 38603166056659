/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Spinner } from 'reactstrap';
import { btn_blue, btn_yellow, btn_red, btn_sm, btn_md, btn_green, btn_pink } from 'src/styles/Btns.module.scss';

export const BtnSize = {
    SM: btn_sm,
    MD: btn_md,
    DEFAULT: '',
};

const BtnColor = {
    BLUE: btn_blue,
    YELLOW: btn_yellow,
    RED: btn_red,
    PINK: btn_pink,
    GREEN: btn_green,
};

const Btn = React.memo(
    ({
        childRef,
        children,
        color,
        RightImg = null,
        HoverImg = null,
        size = BtnSize.DEFAULT,
        width,
        disabled,
        is_loading = false,
        ...args
    }) => {
        return (
            <button
                ref={childRef}
                className={[color, size].join(' ')}
                style={{ ...(width ? { width } : {}) }}
                {...args}
                disabled={disabled || is_loading}>
                {children}
                {!is_loading && RightImg && <RightImg />}
                {!is_loading && HoverImg && !disabled && <HoverImg />}
                {is_loading && <Spinner color='primary' size='sm' />}
            </button>
        );
    },
);

export const BlueBtn = ({ ...props }) => <Btn {...props} color={BtnColor.BLUE} />;
export const YellowBtn = ({ ...props }) => <Btn {...props} color={BtnColor.YELLOW} />;
export const RedBtn = ({ ...props }) => <Btn {...props} color={BtnColor.RED} />;
export const PinkBtn = ({ ...props }) => <Btn {...props} color={BtnColor.PINK} />;
export const GreenBtn = ({ ...props }) => <Btn {...props} color={BtnColor.GREEN} />;
