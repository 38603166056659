import React from 'react';

import DriftL from "react-driftjs";

import { LANGUAGES } from 'src/config/constants';
import { LangContext } from 'src/config/contexts';
const style = {
    "background-color":'#F2BB37',
    bottom: "100px",
}
const Drift = () =>{
    return <DriftL appId="579hf6ir69iw"//"aye5zvsucky4" 
    eventHandlers={[
        { event: "ready", function: hideOnLoad },
      ]}
    />
};
const hideOnLoad = function () {
    setTimeout(()=>window.drift.api.hideChat(),200);
  };

export const openDrift = () =>{
    window.drift.api.openChat();
}


export default Drift;
