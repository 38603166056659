/** @module layout */

import React from 'react';

import Content from 'src/components/layouts/components/Content';

import Footer from './footer/Footer';
import Header from './header';
/**
 * Component BasePage
 *
 * @class BasePage
 * @property {component} children
 * @example
 * return (
 *   <BasePage>
 *      {children}
 *   </BasePage>
 * )
 */

class BasePage extends React.PureComponent {
    render() {
        const { children } = this.props;
        return (
            <>
                <Header />
                <Content>{children}</Content>
                <Footer />
            </>
        );
    }
}

export default BasePage;
