import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AxiosInterceptor from 'src/config/AxiosInterceptor';
import AxiosTranslatorProvider from 'src/config/AxiosTranslatorProvider';
import * as serviceWorker from 'src/config/serviceWorker';
import store, { history } from 'src/config/store';

import 'src/styles/index.scss';
import App from './App';

// axios initialization happens here
AxiosInterceptor();

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            {/* <React.StrictMode> */}
            <AxiosTranslatorProvider>
                <App />
            </AxiosTranslatorProvider>
            {/* </React.StrictMode> */}
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
