/** @module reducers/lending/main */

import { REDUCERS_NAMES } from 'src/config/constants';
import { actionHandlerOnce, baseInitialState, baseReducer, rejectedState, fulfilledState } from 'src/reducers/base';
import { get_main } from 'src/reducers/lending/actions';
/**
 * ACTIONS HANDLERS
 * @function
 */
export const getMain = actionHandlerOnce(REDUCERS_NAMES.MAIN, get_main);

/**
 * INITIAL STATE
 * @constant
 * @type {object}
 * @default
 */
export const initialState = {
    ...baseInitialState,
    pages: {},
    main_page: {
        meta: {},
        header: {
            image: {},
        },
        trusted_companies: {},
        steps: {
            items: [],
        },
        use_wookie: {},
    },
    trusted_companies: [],
    background: {},
    contact_data: {},
};

/**
 * Main Reducer
 */
export default baseReducer(get_main, initialState, {
    [get_main.fulfilled]: (state, action) => {
        return action.payload.isAxiosError || action.payload.data === undefined
            ? { ...state, ...rejectedState() }
            : { ...state, ...fulfilledState, ...action.payload.data };
    },
});
