import React from 'react';

import { content } from 'src/styles/Common.module.scss';

/**
 * Component Content uses in Component BasePage
 * @class Content
 * @memberof module:layout
 * @property {component} children
 * @example
 * return (
 *   <Content>{children}</Content>
 * )
 */
const Content = ({ children }) => <div className={content}>{children}</div>;

export default Content;
