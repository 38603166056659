import React, { useEffect, useContext } from 'react';

import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { LANGUAGES } from 'src/config/constants';
import { LangContext } from 'src/config/contexts';

const setting = {
    color: {
        theme: '#2F3CAF',
        launcher: '#F2BB37',
        launcherText: '#23254D',
    },
    chat: {
        connectOnPageLoad: false,
    },
    launcher: {
        chatLabel: {
            'en-US': 'Need Help',
        },
    },
    contactForm: {
        fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
    },
};

export const openZendesk = () =>
    document.querySelector('iframe#launcher')?.contentDocument?.querySelector('button')?.click();

const ZenDesk = () => {
    const lang = useContext(LangContext);
    useEffect(() => {
        ZendeskAPI('webWidget', 'setLocale', lang === LANGUAGES.EN ? 'en-US' : lang);
    });

    return (
        <Zendesk
            zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...setting}
            onLoaded={() => console.log('is loaded')}
        />
    );
};

export default ZenDesk;
