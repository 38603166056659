import React from 'react';

import { Navbar } from 'reactstrap';
import { Navigation } from 'src/components/layouts/base/Nav';
import { navbar_footer, navbar_footer_link, navbar_footer_list } from 'src/styles/Common.module.scss';

export default React.memo(({ pages, lang, fetched }) => {
    return (
        <>
            <Navbar className={navbar_footer} light expand='md'>
                <Navigation
                    pages={pages}
                    className={navbar_footer_list}
                    lang={lang}
                    class_navbar_link={navbar_footer_link}
                />
            </Navbar>
        </>
    );
});
