/** @module reducers/lending/actions */

import axios from 'axios';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { ACTION_TYPES, API } from 'src/config/constants';
import { createActionReset } from 'src/reducers/base';

/**
 * ACTION create feedback
 * @function
 */
export const create_feedback = createAsyncAction(ACTION_TYPES.POST_FEEDBACK, (data) =>
    axios({ ...API.POST_FEEDBACK, data }),
);

/**
 * ACTION fetch main data
 * @function
 */
export const get_main = createAsyncAction(ACTION_TYPES.GET_MAIN, () => axios(API.GET_MAIN));

/**
 * ACTION fetch solutions data
 * @function
 */
export const get_solutions = createAsyncAction(ACTION_TYPES.GET_SOLUTIONS, () => axios(API.GET_SOLUTIONS));

/**
 * ACTION fetch faqs data
 * @function
 */
export const get_faqs = createAsyncAction(ACTION_TYPES.GET_FAQS, () => axios(API.GET_FAQS));

/**
 * ACTION fetch tariffs data
 * @function
 */
export const get_tariffs = createAsyncAction(ACTION_TYPES.GET_TARIFFS, () => axios(API.GET_TARIFFS));

/**
 * ACTION fetch legal notice data
 * @function
 */
export const get_legal_notice = createAsyncAction(ACTION_TYPES.GET_LEGAL_NOTICE, () => axios(API.GET_LEGAL_NOTICE));

/**
 * ACTION fetch privacy policy data
 * @function
 */
export const get_privacy_policy = createAsyncAction(ACTION_TYPES.GET_PRIVACY_POLICY, () =>
    axios(API.GET_PRIVACY_POLICY),
);

/**
 * ACTION fetch offices data
 * @function
 */
export const get_offices = createAsyncAction(ACTION_TYPES.GET_OFFICES, () => axios(API.GET_OFFICES));

/**
 * ACTION reset feedback
 * @function
 */
export const reset_feedback = createActionReset(ACTION_TYPES.POST_FEEDBACK, (data) => data);
