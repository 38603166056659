import React from 'react';

import { Link } from 'react-router-dom';
import ByAdPremier from 'src/images/logos/ByAdPremier.png';
import AdWookieLogoFooter from 'src/images/logos/Logo-blanc.png';
import GooglePartnerLogoFooter from 'src/images/logos/premier-google-adwords-partner.png';
import { footer_logos, logo, logo_AdPremier, Logo_google_partner_footer } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';

export default React.memo(({ lang }) => (
    <div className={footer_logos}>
        <Link to={createPath([lang])}>
            <img src={AdWookieLogoFooter} alt='logo' className={logo} loading='lazy' width={230} height={65} />
        </Link>
        <a href='https://www.adpremier.fr/?utm_source=adwookie&utm_medium=referral' target='_blance'>
            <img src={ByAdPremier} alt='logo AdPremier' width={230} className={logo_AdPremier} />
        </a>
        <img
            src={GooglePartnerLogoFooter}
            alt='google partner'
            className={Logo_google_partner_footer}
            loading='lazy'
            width={123}
            height={44}
        />
    </div>
));
