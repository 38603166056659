import React from 'react';

import {
    content_container_full,
    content_container_full_bg,
    content_container_sm,
    content_container_md,
    content_container_lg,
} from 'src/styles/Common.module.scss';

export const ContainerWidth = {
    SM: content_container_sm,
    MD: content_container_md,
    LG: content_container_lg,
};

/**
 * Component ContainerFull
 * @class ContainerFull
 * @memberof module:layout
 * @property {component} children
 * @example
 * return (
 *   <Container>{children}</Container>
 * )
 */
export default React.memo(({ children, fill = false, width = ContainerWidth.SM, report }) => (
    <div
        className={[!fill ? content_container_full : content_container_full_bg, width].join(' ')}
        style={{ padding: report && '0 5%' }}>
        {children}
    </div>
));
