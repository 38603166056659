import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import freeze from 'redux-freeze';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import * as all_reducers from '../reducers';

export const history = createBrowserHistory();

const reducers = combineReducers({
    ...all_reducers,
    router: connectRouter(history),
});

const preloadedState = {};
export const middleware = [thunk, routerMiddleware(history), promiseMiddleware];
const dev_middleware = [];

if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
        collapsed: true,
        duration: true,
        diff: true,
    });
    dev_middleware.push(logger);
    dev_middleware.push(freeze);
}

const store = configureStore({
    reducer: reducers,
    middleware: [...middleware, ...dev_middleware],
    preloadedState,
});

export default store;
