/** @module reducers/lending/feedback */

import { actionHandler, actionHandlerReset, baseInitialState, baseReducer } from 'src/reducers/base';
import { create_feedback, reset_feedback } from 'src/reducers/lending/actions';

/**
 * INITIAL STATE
 * @constant
 * @type {object}
 * @default
 */
export const initialState = {
    ...baseInitialState,
};

/**
 * Create feedback
 * @function
 */
export const postFeedback = actionHandler(create_feedback);

/**
 * Reset store
 * @function
 */
export const resetFeedback = actionHandlerReset(reset_feedback, initialState);

/**
 * FeedBack Reducer
 */
export default baseReducer(create_feedback, initialState);
