import React, { useContext } from 'react';

import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { PAGES_IDS } from 'src/config/constants';
import { LangContext } from 'src/config/contexts';
import { cookies_policy_text } from 'src/config/translation';
import { copyright } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';

const CopyRight = React.memo(({ pages }) => {
    const lang = useContext(LangContext);
    return (
        <div className={copyright}>
            &copy; 2020, AdWookie |{' '}
            {pages?.[PAGES_IDS.PRIVACY_POLICY] && (
                <HashLink smooth to={`${createPath([lang, pages[PAGES_IDS.PRIVACY_POLICY].slug[lang]])}#cookies`}>
                    {cookies_policy_text[lang]}
                </HashLink>
            )}
        </div>
    );
});

export default connect(
    ({ main: { pages } }) => ({
        pages,
    }),
    {},
)(CopyRight);
