/** @module utils/savePDF */

/**
 * Saves pdf as file
 * @function
 * @param {string} file_name
 * @param {string} data
 * @returns {void}
 */
export const savePDF = ({ file_name, data }) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file_name);
    link.click();
    link.remove();
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
};

/**
 * returns name of pdf file
 * @function
 * @returns {string}
 */
export const reportFileNameFormat = ({ id, account_id, period, account_type, lang }) =>
    `report_#${id}_${account_id}_${period.replace(/[ ]+/g, '')}_${account_type}_${lang}.pdf`.replace(/[ ]+/g, '_');
