import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ModalWindowLogoImg } from 'src/components/layouts/base/images';
import { PAGES_IDS } from 'src/config/constants';
import { modals_text } from 'src/config/translation';
import { modal_body_inner } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';

import { BlueBtn, BtnSize, RedBtn } from '../layouts/base/btns/btns';

const CreateReport403Modal = React.memo(({ isOpen, onOk, detail, lang, pages }) => {
    const page_pricing_slug = pages[PAGES_IDS.PRICING]?.slug;
    return (
        <div>
            <Modal isOpen={isOpen} toggle={onOk}>
                <ModalHeader toggle={onOk} />
                <ModalBody>
                    <div className={modal_body_inner}>
                        <ModalWindowLogoImg />
                        <div>
                            <div>{modals_text.ALERT_CREATE_REPORT_403_TITLE[lang]}</div>
                            {detail && <div>{detail}</div>}
                            {page_pricing_slug && (
                                <Link to={createPath([lang, page_pricing_slug[lang]])}>
                                    {modals_text.BTNS.VIEW_TARIFFS[lang]}
                                </Link>
                            )}
                            <div>
                                {page_pricing_slug && (
                                    <Link to={createPath([lang, page_pricing_slug[lang]])}>
                                        <BlueBtn size={BtnSize.MD} onClick={onOk}>
                                            {modals_text.BTNS.VIEW_TARIFFS[lang]}
                                        </BlueBtn>
                                    </Link>
                                )}
                                <RedBtn size={BtnSize.MD} onClick={onOk}>
                                    {modals_text.BTNS.CANCEL[lang]}
                                </RedBtn>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
});

export default connect(({ main: { pages }, lang: { lang } }) => ({
    pages,
    lang,
}))(CreateReport403Modal);
