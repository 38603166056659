import React from 'react';

import { Link } from 'react-router-dom';
import AdWookieLogoHeader from 'src/images/logos/Logo-blanc.png';
import { header_logo } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';

export default React.memo(({ lang }) => (
    <div>
        <Link to={createPath([lang])}>
            <img src={AdWookieLogoHeader} alt='logo' className={header_logo} loading='lazy' width={230} height={65} />
        </Link>
    </div>
));
