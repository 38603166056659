import React from 'react';

import { content_container_row } from 'src/styles/Common.module.scss';

/**
 * Component ContainerRow has max width
 * @class ContainerRow
 * @memberof module:layout
 * @property {component} children
 * @example
 * return (
 *   <ContainerRow>{children}</ContainerRow>
 * )
 */
export default React.memo(({ children, norelative = false }) => (
    <div className={content_container_row} style={!norelative ? { position: 'relative' } : {}}>
        {children}
    </div>
));
