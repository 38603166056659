import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ModalWindowLogoImg } from 'src/components/layouts/base/images';
import { modal_body_inner } from 'src/styles/Common.module.scss';

import { BlueBtn, BtnSize } from '../layouts/base/btns/btns';

export default React.memo(({ isOpen, setModal = () => {}, title, text, ok_text, onOk = () => {} }) => {
    const toggle = () => setModal(!isOpen);
    const onOkHandler = () => {
        toggle();
        onOk();
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle} />

                <ModalBody>
                    <div className={modal_body_inner}>
                        <ModalWindowLogoImg />
                        <div>
                            {title && <div>{title}</div>}
                            {text && <div>{text}</div>}
                            <div>
                                <BlueBtn size={BtnSize.MD} onClick={onOkHandler}>
                                    {ok_text}
                                </BlueBtn>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
});
