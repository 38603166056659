import React, { useContext, useMemo } from 'react';

import { connect } from 'react-redux';
import AlertModal from 'src/components/modals/AlertModal';
import { LangContext } from 'src/config/contexts';
import { modals_text } from 'src/config/translation';
import { resetAccount } from 'src/reducers/account/auth';

const AuthAlertModal = React.memo(({ is_error, error, resetAccount }) => {
    const lang = useContext(LangContext);
    const auth_error_keys_list = Object.keys(error);
    const modal_title = Object.values(error)[0];
    const auth_error_keys_list_in_string = useMemo(() => auth_error_keys_list.join(', '), [auth_error_keys_list]);
    return (
        <AlertModal
            isOpen={is_error}
            title={modal_title || modals_text.ALERT_ERROR_AUTH_KEYS_TITLE[lang]}
            text={`${modals_text.ALERT_ERROR_AUTH_KEYS_TEXT[lang]} ${auth_error_keys_list_in_string}`}
            ok_text={modals_text.BTNS.OK[lang]}
            setModal={resetAccount}
        />
    );
});

export default connect(
    ({ auth: { is_error, error } }) => ({
        is_error,
        error,
    }),
    {
        resetAccount,
    },
)(AuthAlertModal);
