import React, { useMemo } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LANGUAGES, STATIC_PAGES_SLUGS, APPS_PATHS, HEADERS } from 'src/config/constants';
import { history } from 'src/config/store';
import ImageEN from 'src/images/languages/en.svg';
import ImageFR from 'src/images/languages/fr.svg';
import { setLang } from 'src/reducers/common/lang';
import { language_panel, btn_change_language, btn_change_language_active } from 'src/styles/Common.module.scss';

// method what translate all words in url using vocabulary
const translateURL = (location, vocabulary, lang, setLang) => (new_lang_code) => () => {
    if (new_lang_code !== lang && Object.values(LANGUAGES).includes(new_lang_code)) {
        const slugs = [...vocabulary, ...Object.values(STATIC_PAGES_SLUGS), ...Object.values(APPS_PATHS)];
        const path_arr = location.pathname.split('/');
        slugs
            .map((slug) => (typeof slug === 'function' && slug('')) || slug)
            .filter((slug) => slug[lang].length)
            .forEach((slug) => {
                const current_slug = slug[lang];
                if (path_arr.includes(current_slug)) {
                    path_arr[path_arr.indexOf(current_slug)] = slug[new_lang_code];
                }
            });
        if (path_arr.includes(lang)) {
            path_arr[path_arr.indexOf(lang)] = new_lang_code;
        }
        const new_path = path_arr.join('/');
        axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            [HEADERS.ACCEPT_LANGUAGE]: new_lang_code,
        };
        setLang(new_lang_code);
        history.push(new_path);
    }
};

const LanguagePanel = React.memo(({ pages, lang, setLang, faqs_data = [], report_page_types = [] }) => {
    const location = useLocation();

    const vocabulary = useMemo(
        () => [...Object.values(pages), ...faqs_data, ...report_page_types].map((page_v) => page_v.slug),
        [pages, faqs_data, report_page_types],
    );
    const toTranslateURL = translateURL(location, vocabulary, lang, setLang);

    const btn_EN_class = useMemo(() => {
        return lang === LANGUAGES.EN ? btn_change_language_active : btn_change_language;
    }, [lang]);
    const btn_FR_class = useMemo(() => {
        return lang === LANGUAGES.FR ? btn_change_language_active : btn_change_language;
    }, [lang]);
    return (
        <div className={language_panel}>
            <button className={btn_EN_class} onClick={toTranslateURL(LANGUAGES.EN)}>
                <img src={ImageEN} alt='translate to english version' width={20} height={20} loading='lazy' />
                <span>{LANGUAGES.EN}</span>
            </button>
            <button className={btn_FR_class} onClick={toTranslateURL(LANGUAGES.FR)}>
                <img src={ImageFR} alt='translate to french version' width={20} height={20} loading='lazy' />
                <span>{LANGUAGES.FR}</span>
            </button>
        </div>
    );
});

export default connect(
    ({ lang: { lang }, faqs, auth }) => ({
        lang,
        faqs_data: faqs.data,
        report_page_types: auth.data.report_page_types,
    }),
    {
        setLang,
    },
)(LanguagePanel);
