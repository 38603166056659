import React, { useEffect, useContext } from 'react';

import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import CreateReport403Modal from 'src/components/modals/CreateReport403Modal';
import { APPS_PATHS, STATIC_PAGES_SLUGS } from 'src/config/constants';
import { LangContext } from 'src/config/contexts';
import { history } from 'src/config/store';
import { resetCreateReport } from 'src/reducers/account/reducers';
import { loading_indicator_block_all } from 'src/styles/Common.module.scss';
import { createPath } from 'src/utils/pathManager';

const FixedLoadingIndicator = React.memo(
    ({
        report_create: {
            is_fetched,
            is_loading,
            data: { id, detail },
            is_error,
        },
        resetCreateReport,
    }) => {
        const lang = useContext(LangContext);
        useEffect(() => {
            if (is_fetched && !is_error) {
                resetCreateReport();
                history.push(createPath([lang, APPS_PATHS.ACCOUNT[lang], STATIC_PAGES_SLUGS.REPORT(id)[lang]]));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [is_fetched, is_error]);
        return (
            <>
                <CreateReport403Modal isOpen={is_error} detail={detail} onOk={resetCreateReport} lang={lang} />
                {is_loading && (
                    <div className={loading_indicator_block_all}>
                        <Spinner color='primary' />
                    </div>
                )}
            </>
        );
    },
);

export default connect(
    ({ report_create }) => ({
        report_create,
    }),
    { resetCreateReport },
)(FixedLoadingIndicator);
