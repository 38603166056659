/** @module config/static-translation */

import {
    LANGUAGES,
    BUTTONS_TYPES,
    FORM_INPUTS,
    FORM_CREATE_REPORT_INPUTS,
    SORTING_TYPES,
    REPORT_PAGE_MARK,
} from 'src/config/constants';

/**
 * translation of texts of 3 btns AUDIT | APPOINTMENT | QUOTE
 * @readonly
 * @constant
 */
export const btn_text = {
    [BUTTONS_TYPES.AUDIT]: {
        [LANGUAGES.FR]: 'Audit Gratuit',
        [LANGUAGES.EN]: 'Free Audit',
    },
    [BUTTONS_TYPES.APPOINTMENT]: {
        [LANGUAGES.FR]: 'Demander un devis',
        [LANGUAGES.EN]: 'Request a quote',
    },
    [BUTTONS_TYPES.QUOTE]: {
        [LANGUAGES.FR]: 'DEVIS',
        [LANGUAGES.EN]: 'QUOTE',
    },
};

export const filterGlobalReports = {
    [LANGUAGES.EN]: ['Growth Opportunity in Research', 'Growth Opportunity in Shopping'],
    [LANGUAGES.FR]: ['Opportunité de Croissance sur la Recherche', 'Opportunité de Croissance sur le Shopping'],
};

export const report_with_google_data = {
    [LANGUAGES.EN]: ['age', 'device', 'gender', 'hours', 'regions', 'days'],
    [LANGUAGES.FR]: ['âge', 'appareil', 'sexe', 'heures', 'régions', 'jours'],
};

export const titels_audience = {
    [LANGUAGES.EN]: ['age', 'device', 'gender', 'hours of the day', 'regions', 'days of the week'],
    [LANGUAGES.FR]: ['Âge', 'appareil', 'Sexe', 'heures de la journée', 'régions', 'jours de la semaine']
}

/**
 * translation of logout btn
 * @readonly
 * @constant
 */
export const logout_btn_text = {
    [LANGUAGES.FR]: 'Se déconnecter',
    [LANGUAGES.EN]: 'Logout',
};

/**
 * title of filters in menu
 * @readonly
 * @constant
 */
export const menu_filters_title_text = {
    [LANGUAGES.FR]: 'Mes objectifs',
    [LANGUAGES.EN]: 'Mes objectifs',
};

/**
 * translation of btn in main page in FQA area
 * @readonly
 * @constant
 */
export const main_FAQ_btn_text = {
    [LANGUAGES.FR]: 'Un wookie peut se mobiliser pour vous',
    [LANGUAGES.EN]: 'Un wookie peut se mobiliser pour vous',
};

/**
 * translation of first title in main page in FQA area
 * @readonly
 * @constant
 */
export const main_FAQ_title1_text = {
    [LANGUAGES.FR]: 'Vous avez une question\u00A0?',
    [LANGUAGES.EN]: 'Vous avez une question\u00A0?',
};

/**
 * translation of first title in main page in FQA area
 * @readonly
 * @constant
 */
export const main_FAQ_title2_text = {
    [LANGUAGES.FR]: 'Vous voulez en savoir plus\u00A0?',
    [LANGUAGES.EN]: 'Vous voulez en savoir plus\u00A0?',
};

export const filterMenu = {
    FIRST: {
        [LANGUAGES.EN]: 'Savings',
        [LANGUAGES.FR]: 'Economiser',
    },
    SECOND: {
        [LANGUAGES.EN]: 'Visibility',
        [LANGUAGES.FR]: 'Augmenter ma visibilité',
    },
    THIRD: {
        [LANGUAGES.EN]: 'Google score',
        [LANGUAGES.FR]: 'Améliorer mon scoring Google',
    },
    FOURTH: {
        [LANGUAGES.EN]: 'Audience',
        [LANGUAGES.FR]: 'Ajuster ma cible',
    },
};

export const period = {
    [LANGUAGES.FR]: 'Période',
    [LANGUAGES.EN]: 'Period'
}

/**
 * translation of fields in feedback form
 * @readonly
 * @constant
 */
export const form_label_text = {
    [FORM_INPUTS.NAME]: {
        [LANGUAGES.FR]: 'Nom',
        [LANGUAGES.EN]: 'Name',
    },
    [FORM_INPUTS.EMAIL]: {
        [LANGUAGES.FR]: 'E-mail',
        [LANGUAGES.EN]: 'E-mail',
    },
    [FORM_INPUTS.WEB_SITE]: {
        [LANGUAGES.FR]: 'Website',
        [LANGUAGES.EN]: 'Website',
    },
    [FORM_INPUTS.PHONE]: {
        [LANGUAGES.FR]: 'Numéro de téléphone',
        [LANGUAGES.EN]: 'Phone number',
    },
    [FORM_INPUTS.MESSAGE]: {
        [LANGUAGES.FR]: 'Message',
        [LANGUAGES.EN]: 'Message',
    },
};

/**
 * translation of text about private policy in form
 * @readonly
 * @constant
 */
export const form_checkbox_text = {
    [LANGUAGES.FR]: `En soumettant ce formulaire, j'accepte que les informations saisies dans ce formulaire soient
    utilisées pour permettre de me recontacter. J’affirme avoir pris connaissance de notre politique de
    confidentialité.`,
    [LANGUAGES.EN]: `By submitting this form, I accept that the information entered in this form will be used to
    allow me to contact me. I confirm that I have read our privacy policy.`,
};

/**
 * translation of texts of feedback form submit
 * @readonly
 * @constant
 */
export const form_btn_submit_text = {
    [LANGUAGES.FR]: 'Envoyer',
    [LANGUAGES.EN]: 'Send',
};

/**
 * translation of feedback form reset btn
 * @readonly
 * @constant
 */
export const form_btn_reset_text = {
    [LANGUAGES.FR]: 'Renvoyer',
    [LANGUAGES.EN]: 'Send again',
};

/**
 * translation of feedback form reset msg
 * @readonly
 * @constant
 */
export const form_text_reset_text = {
    [LANGUAGES.FR]: 'Envoyé par',
    [LANGUAGES.EN]: 'Sent by',
};

/**
 * translation of offices title
 * @readonly
 * @constant
 */
export const offices_title_text = {
    [LANGUAGES.FR]: 'Retrouvez-nous...',
    [LANGUAGES.EN]: 'Offices',
};

/**
 * forbidden report if client has type kpi CPA
 */
export const forbidden_cpa_type_report = {
    FIRST: {
        [LANGUAGES.FR]: 'Produits avec un bon CPA',
        [LANGUAGES.EN]: 'Products with a good CPA',
    },
    SECOND: {
        [LANGUAGES.FR]: 'Produits avec un CPA supérieur à la moyenne',
        [LANGUAGES.EN]: 'Products with a higher than average CPA',
    },
};
/**
 * forbidden report all clients
 */

export const forbidden_type_report = {
    FIRST: {
        [LANGUAGES.FR]: 'Détail des ETA manquantes',
        [LANGUAGES.EN]: 'Missing ETA Details',
    },
    SECOND: {
        [LANGUAGES.FR]: 'Détail des RSA manquantes',
        [LANGUAGES.EN]: 'Missing RSA detail',
    },
    THIRD: {
        [LANGUAGES.FR]: 'Produits avec un CPA supérieur à la moyenne',
        [LANGUAGES.EN]: 'Products with an above-average CPA',
    },
    FOURTY: {
        [LANGUAGES.FR]: 'Détail des Extensions manquantes',
        [LANGUAGES.EN]: 'Missing Ad Extensions Details',
    },
};

/**
 * translation of feedback form title
 * @readonly
 * @constant
 */
export const form_title_text = {
    [LANGUAGES.FR]: "Demande de renseignements",
    [LANGUAGES.EN]: 'Get more information',
};

export const text_google_data = {
    [LANGUAGES.EN]: 'In this View, you can find additionnal Google Ads data required by Google.',
    [LANGUAGES.FR]: 'Dans cette vue, vous trouverez des donées additionnelles de Google Ads étant requises par Google.',
};

/**
 * translation of menu's name in FAQ
 * @readonly
 * @constant
 */
export const nav_title_text = {
    [LANGUAGES.EN]: 'Quick navigation',
    [LANGUAGES.FR]: 'Navigation rapide',
};

/**
 * translation of btn 'analize' on main page
 * @readonly
 * @constant
 */
export const analize_text = {
    [LANGUAGES.FR]: "LANCER L'ANALYSE GRATUITEMENT",
    [LANGUAGES.EN]: 'LAUNCH THE ANALYSIS FOR FREE',
};
/**
 * translation of btn 'discover_training' on main page
 * @readonly
 * @constant
 */
export const discover_training_text = {
    [LANGUAGES.FR]: "DÉCOUVREZ NOS FORMATIONS",
    [LANGUAGES.EN]: 'DISCOVER OUR TRAINING',
};

/**
 * translation of btn 'training' on main page
 * @readonly
 * @constant
 */
 export const training_text = {
    [LANGUAGES.FR]: "SE FORMER À GOOGLE AD",
    [LANGUAGES.EN]: 'GET TRAINING ON GOOGLE AD',
};

/**
 * translation of btn 'analize' on main page
 * @readonly
 * @constant
 */
export const pagination_text = {
    [LANGUAGES.FR]: (number) => `Afficher ${number} Par Page`,
    [LANGUAGES.EN]: (number) => `Display ${number} Per Page`,
};

/**
 * translation of 404 page title
 * @readonly
 * @constant
 */
export const not_found_page_title_text = {
    [LANGUAGES.EN]: '404',
    [LANGUAGES.FR]: '404',
};

/**
 * translation of 404 page description
 * @readonly
 * @constant
 */
export const not_found_page_description_text = {
    [LANGUAGES.EN]: 'Not Found this page',
    [LANGUAGES.FR]: 'Page non trouvée',
};

/**
 * translation of title dashboard page
 * @readonly
 * @constant
 */
export const dashboard_page_title_text = {
    [LANGUAGES.EN]: 'My Dashboard',
    [LANGUAGES.FR]: 'Mon tableau de bord',
};

/**
 * translation of title choose_account page
 * @readonly
 * @constant
 */
export const choose_account_page_title_text = {
    [LANGUAGES.EN]: 'Choose account for audit',
    [LANGUAGES.FR]: 'Choisissez le compte à auditer',
};

/**
 * translation of error page title
 * @readonly
 * @constant
 */
export const error_page_title_text = {
    [LANGUAGES.EN]: 'Error',
    [LANGUAGES.FR]: 'Erreur',
};

/**
 * translation of error page description
 * @readonly
 * @constant
 */
export const error_page_description_text = {
    [LANGUAGES.EN]: 'Something went wrong with this page',
    [LANGUAGES.FR]: "Une erreur s'est produite avec cette page",
};

/**
 * texts for subscription page
 * @readonly
 * @constant
 */
export const subscription_text = {
    NAME: {
        [LANGUAGES.EN]: 'Subscription name',
        [LANGUAGES.FR]: "Type d'abonnement",
    },
    ACCOUNT: {
        [LANGUAGES.EN]: 'Account',
        [LANGUAGES.FR]: 'Compte',
    },
    START_DATE: {
        [LANGUAGES.EN]: 'Subscription start date',
        [LANGUAGES.FR]: "Date de début d'abonnement",
    },
    EXPIRY_DATE: {
        [LANGUAGES.EN]: 'Subscription expiry date',
        [LANGUAGES.FR]: "Date d'expiration de l'abonnement",
    },
    CHANGE_SUBSCRIPTION_BTN: {
        [LANGUAGES.EN]: 'Change Subscription',
        [LANGUAGES.FR]: "Changer l'abonnement",
    },
};

/**
 * texts for dashboard
 * @readonly
 * @constant
 */
export const reports_text = {
    REPORT_TITLE: {
        [LANGUAGES.EN]: 'Google Account: ',
        [LANGUAGES.FR]: 'Compte Google\u00A0: ',
    },
    REPORT_PERIOD: {
        [LANGUAGES.EN]: 'Audit period: ',
        [LANGUAGES.FR]: "Période d'audit\u00A0: ",
    },
    CREATED_AT: {
        [LANGUAGES.EN]: 'Creation date: ',
        [LANGUAGES.FR]: 'Date de création\u00A0: ',
    },
    // HTML_BTN: {
    //     [LANGUAGES.EN]: 'View',
    //     [LANGUAGES.FR]: 'Vue',
    // },
    PDF_BTN: {
        [LANGUAGES.EN]: 'PDF',
        [LANGUAGES.FR]: 'PDF',
    },
    STOP_BTN: {
        [LANGUAGES.EN]: 'stop',
        [LANGUAGES.FR]: 'pause',
    },
    START_BTN: {
        [LANGUAGES.EN]: 'start',
        [LANGUAGES.FR]: 'lancer',
    },
    SEND_BTN: {
        [LANGUAGES.EN]: 'Send',
        [LANGUAGES.FR]: 'Envoyer',
    },
    DEL_BTN: {
        [LANGUAGES.EN]: 'remove',
        [LANGUAGES.FR]: 'supprimer',
    },
    TITLE: {
        [LANGUAGES.EN]: 'Reports of audit',
        [LANGUAGES.FR]: "Rapports de l'audit",
    },
    EMPTY: {
        [LANGUAGES.EN]: 'empty list',
        [LANGUAGES.FR]: 'La liste est vide',
    },
    REPORT_EMAIL_SEND_TITLE: {
        [LANGUAGES.EN]: 'Your email is not sent yet!',
        [LANGUAGES.FR]: "Votre e-mail n'est pas encore envoyé",
    },
    REPORT_EMAIL_REPEAT_TITLE: {
        [LANGUAGES.EN]: 'Your email is already sent!',
        [LANGUAGES.FR]: 'Votre e-mail est déjà envoyé',
    },
    REPORT_EMAIL_SEND_BTN: {
        [LANGUAGES.EN]: 'send report to email',
        [LANGUAGES.FR]: 'Envoyer le rapport par mail',
    },
    REPORT_EMAIL_REPEAT_BTN: {
        [LANGUAGES.EN]: 'send report again',
        [LANGUAGES.FR]: 'Renvoyer le rapport',
    },
    ACCOUNT_TYPE: {
        [LANGUAGES.EN]: 'Account Type: ',
        [LANGUAGES.FR]: 'Type de compte\u00A0: ',
    },
    OPEN_CHAT_BTN: {
        [LANGUAGES.EN]: 'Chat with a Wookie!',
        [LANGUAGES.FR]: 'Chatter avec un Wookie\u00A0!',
    },
    MAKE_FOR_ME_BTN: {
        [LANGUAGES.EN]: 'Do it for me!',
        [LANGUAGES.FR]: 'Faites-le pour moi\u00A0!',
    },
    ADWOOKIE_VIEW: {
        [LANGUAGES.EN]: 'adwookie view',
        [LANGUAGES.FR]: 'Vue AdWookie',
    },
    GOOGLE_DATA_VIEW: {
        [LANGUAGES.EN]: 'Google Additional Data',
        [LANGUAGES.FR]: 'Données Additionnelles',
    },
    UNDERSTANDING_BTN: {
        [LANGUAGES.EN]: 'Better understand this report',
        [LANGUAGES.FR]: 'Mieux comprendre ce rapport',
    },
    STATUS_TITLE: {
        [LANGUAGES.EN]: 'Status',
        [LANGUAGES.FR]: 'Statut',
    },
    STATUSES: {
        [true]: {
            [LANGUAGES.EN]: 'active',
            [LANGUAGES.FR]: 'actif',
        },
        [false]: {
            [LANGUAGES.EN]: 'inactive',
            [LANGUAGES.FR]: 'inactif',
        },
    },
    COUNT_REPORTS: {
        [LANGUAGES.EN]: 'Number or reports',
        [LANGUAGES.FR]: 'Nombre de rapports',
    },
    LAST_REPORT_DATE: {
        [LANGUAGES.EN]: 'Date of last report',
        [LANGUAGES.FR]: 'Date du dernier rapport',
    },
    NEXT_REPORT_DATE: {
        [LANGUAGES.EN]: 'Date of next report',
        [LANGUAGES.FR]: 'Date du prochain rapport',
    },
    PERIOD: {
        [LANGUAGES.EN]: 'Period',
        [LANGUAGES.FR]: 'Période',
    },
    MONTHS: {
        [LANGUAGES.EN]: 'months',
        [LANGUAGES.FR]: 'mois',
    },
    EXPECTED: {
        [LANGUAGES.EN]: 'Expected...',
        [LANGUAGES.FR]: 'En attente...',
    },
    GRANT_ACCESS: {
        [LANGUAGES.EN]: 'Grant Access',
        [LANGUAGES.FR]: "Demande d'accès",
    },
    MCC_ACCESS_LOST: {
        [LANGUAGES.EN]: 'MCC access lost',
        [LANGUAGES.FR]: 'Accès au MCC est perdu',
    },
    REPORT_DISABLED_FIREFOX: {
        [LANGUAGES.EN]: `Your browser does not support slides displaying.
            Please, use Google Chrome for viewing the slides or download PDF file.`,
        [LANGUAGES.FR]: `Votre navigateur ne permet pas l'affichage des diapositives de rapport.
        Veuillez utiliser Google Chrome ou télécharger un rapport en PDF pour pouvoir consulter les diapositives .`,
    },
    REPORT_DISABLED_MOBILE: {
        [LANGUAGES.EN]:
            'Your device does not support slides displaying. ' +
            'Please, use Google Chrome for Desktop for or download PDF file.',
        [LANGUAGES.FR]: `Votre appareil mobile ne permet pas l'affichage des diapositives de rapport.
        Veuillez utiliser Google Chrome sur votre ordinateur ou télécharger un rapport en PDF
        pour pouvoir consulter les diapositives .`,
    },
};

/**
 * texts for dashboard
 * @readonly
 * @constant
 */
export const modals_text = {
    BTNS: {
        CANCEL: {
            [LANGUAGES.EN]: 'cancel',
            [LANGUAGES.FR]: 'annuler',
        },
        DISCARD: {
            [LANGUAGES.EN]: 'discard',
            [LANGUAGES.FR]: 'jeter',
        },
        OK: {
            [LANGUAGES.EN]: 'ok',
            [LANGUAGES.FR]: 'ok',
        },
        CLOSE: {
            [LANGUAGES.EN]: 'close',
            [LANGUAGES.FR]: 'fermer',
        },
        DELETE: {
            [LANGUAGES.EN]: 'delete',
            [LANGUAGES.FR]: 'supprimer',
        },
        CREATE: {
            [LANGUAGES.EN]: 'create',
            [LANGUAGES.FR]: 'créer',
        },
        VIEW_TARIFFS: {
            [LANGUAGES.EN]: 'View tariffs',
            [LANGUAGES.FR]: 'Voir le prix',
        },
    },
    CONFIRM_DELETING_TITLE: {
        [LANGUAGES.EN]: 'Confirm deleting please!',
        [LANGUAGES.FR]: 'Veuillez confirmer la suppression',
    },
    CONFIRM_CREATE_REPORT_TITLE: {
        [LANGUAGES.EN]: 'Confirm creating please',
        [LANGUAGES.FR]: 'Veuillez confirmer la création',
    },
    ALERT_CREATE_REPORT_TITLE: {
        [LANGUAGES.EN]: 'Report creating started successfully',
        [LANGUAGES.FR]: 'La création du rapport est lancée  avec succès',
    },
    ALERT_ERROR_CREATE_REPORT_TITLE: {
        [LANGUAGES.EN]: 'You can not create new reports, monthly limit reached',
        [LANGUAGES.FR]: 'Vous ne pouvez pas créer de nouveaux rapports, limite mensuelle atteinte',
    },
    ALERT_CREATE_REPORT_403_TITLE: {
        [LANGUAGES.EN]: 'Permission denied',
        [LANGUAGES.FR]: 'Permission refusée',
    },
    ALERT_ERROR_AUTH_KEYS_TITLE: {
        [LANGUAGES.EN]: 'Something went wrong',
        [LANGUAGES.FR]: 'Something went wrong',
    },
    ALERT_ERROR_AUTH_KEYS_TEXT: {
        [LANGUAGES.EN]: 'Please, allow access to: ',
        [LANGUAGES.FR]: 'Please, allow access to: ',
    },
    // ALERT_CREATE_REPORT_403_TEXT: {
    //     [LANGUAGES.EN]: 'monthly reporting limit has been reached',
    //     [LANGUAGES.FR]: 'la limite de déclaration mensuelle a été atteinte',
    // },
};

/**
 * texts for create report form
 * @readonly
 * @constant
 */
export const form_create_report_label_text = {
    [FORM_CREATE_REPORT_INPUTS.KPI]: {
        [LANGUAGES.EN]: 'KPI',
        [LANGUAGES.FR]: 'KPI',
    },
    [FORM_CREATE_REPORT_INPUTS.ACCOUNT]: {
        [LANGUAGES.EN]: 'Account',
        [LANGUAGES.FR]: 'Compte',
    },
    PERIOD: {
        [LANGUAGES.EN]: 'Period',
        [LANGUAGES.FR]: 'Période',
    },
    [FORM_CREATE_REPORT_INPUTS.EMAIL]: {
        [LANGUAGES.EN]: 'Email',
        [LANGUAGES.FR]: 'Email',
    },
    [FORM_CREATE_REPORT_INPUTS.SEND_REPORT_ON_EMAIL]: {
        [LANGUAGES.EN]: 'Send report on\u00A0e-mail',
        [LANGUAGES.FR]: 'Envoyer un rapport par\u00A0e-mail',
    },
};

/**
 * texts for submit btn of create report form
 * @readonly
 * @constant
 */
export const form_create_report_submit_btn_text = {
    [LANGUAGES.EN]: 'Create Report',
    [LANGUAGES.FR]: 'Créer le rapport',
};
export const create_report_page_monthly_report_input_text = {
    [LANGUAGES.EN]: 'Monthly report',
    [LANGUAGES.FR]: 'Rapport mensuel',
};

/**
 * texts no campaigns
 * @readonly
 * @constant
 */
export const no_campaigns_text = {
    [LANGUAGES.EN]: 'no campaigns',
    [LANGUAGES.FR]: 'pas de capmagnes',
};

/**
 * texts for select of sorting
 * @readonly
 * @constant
 */
export const sorting_select_text = {
    title: {
        [LANGUAGES.EN]: 'Sorting',
        [LANGUAGES.FR]: 'Tri',
    },
    [SORTING_TYPES.BY_ID]: {
        [LANGUAGES.EN]: 'by ID',
        [LANGUAGES.FR]: 'par ID',
    },
    [SORTING_TYPES.BY_NANE]: {
        [LANGUAGES.EN]: 'by name',
        [LANGUAGES.FR]: 'par nom',
    },
    [SORTING_TYPES.BY_CREATED_AT_LARGER_TO_SMALLER]: {
        [LANGUAGES.EN]: 'by creation date',
        [LANGUAGES.FR]: 'par date de création',
    },
    [SORTING_TYPES.BY_LARGER_TO_SMALLER]: {
        [LANGUAGES.EN]: 'from larger to smaller by number of reports',
        [LANGUAGES.FR]: 'from larger to smaller by number of reports fr',
    },
    [SORTING_TYPES.BY_SMALLER_TO_LARGER]: {
        [LANGUAGES.EN]: 'from smaller to larger by number of reports',
        [LANGUAGES.FR]: 'from smaller to larger by number of reports fr',
    },
};

/**
 * texts for btn choose account
 * @readonly
 * @constant
 */
export const choose_account = {
    [LANGUAGES.EN]: 'choose account',
    [LANGUAGES.FR]: 'choisir compte',
};

/**
 * texts for select of sorting
 * @readonly
 * @constant
 */
export const filter_text = {
    filter_all_accounts: {
        [LANGUAGES.EN]: 'Filter by account',
        [LANGUAGES.FR]: 'Filtrer par compte',
    },
};

/**
 * texts for search bar
 * @readonly
 * @constant
 */
export const searchbar_text = {
    title: {
        [LANGUAGES.EN]: 'Search',
        [LANGUAGES.FR]: 'Recherche',
    },
    // results: {
    //     [LANGUAGES.EN]: 'results what including substring:',
    //     [LANGUAGES.FR]: 'résultats incluant une sous-ligne:',
    // },
};

/**
 * texts for charts
 * @readonly
 * @enum {string}
 * @property {string} BY_ID
 * @property {number} BY_LARGER_TO_SMALLER
 * @property {number} BY_SMALLER_TO_LARGER
 */
export const charts_text = {
    NO_DATA: {
        [LANGUAGES.EN]: 'No data',
        [LANGUAGES.FR]: 'Aucune donnée',
    },
    NOT_VALID_DATA: {
        [LANGUAGES.EN]: 'Not valid data',
        [LANGUAGES.FR]: 'Données non valides',
    },
    RATE_AXE: {
        [LANGUAGES.EN]: 'Rate',
        [LANGUAGES.FR]: 'Dépense', // 'Dépense',
    },
    NO_ADWORDS_ACCOUNTS: {
        [LANGUAGES.EN]: 'We cannot find any Google Ads account attached to this email',
        [LANGUAGES.FR]: 'Nous ne trouvons aucun compte Google Ads rattaché à ce mail',
    },
    NO_ADWORDS_ACCOUNTS_BUTTON: {
        [LANGUAGES.EN]: 'Back to login',
        [LANGUAGES.FR]: 'Retour login',
    },
};

/**
 * texts for not enough data in google account
 * @readonly
 * @enum {string}
 */
export const not_enough_data_in_adwords = {
    NO_DATA: {
        [LANGUAGES.EN]: 'You do not have data! Please choose another period of time.',
        [LANGUAGES.FR]: "Vous n'avez pas de données. Merci de choisir une notre période.",
    },
    NOT_ENOUGH_DATA: {
        [LANGUAGES.EN]: 'Not enough data.',
        [LANGUAGES.FR]: 'Pas suffisamment de données.',
    },
};

/**
 * texts for mark of report page
 * @readonly
 * @enum {string}
 */
export const report_page_mark_text = {
    TITLE: {
        [LANGUAGES.EN]: 'Rate this report',
        [LANGUAGES.FR]: 'Notez ce rapport',
    },
    DESCRIPTION: {
        [LANGUAGES.EN]: 'How difficult is it to understand this slide?',
        [LANGUAGES.FR]: 'Quelle est la difficulté pour comprendre ce slide?',
    },
    MESSAGE: {
        [LANGUAGES.EN]: 'Comment',
        [LANGUAGES.FR]: 'Votre text',
    },
    MARKS: {
        [REPORT_PAGE_MARK.GOOD]: {
            [LANGUAGES.EN]: 'Easy',
            [LANGUAGES.FR]: 'Facile',
        },
        [REPORT_PAGE_MARK.NORMAL]: {
            [LANGUAGES.EN]: 'Moderate',
            [LANGUAGES.FR]: 'Modéré',
        },
        [REPORT_PAGE_MARK.BAD]: {
            [LANGUAGES.EN]: 'Difficult',
            [LANGUAGES.FR]: 'Difficile',
        },
    },
};

/**
 * texts for mark of report page
 * @readonly
 * @enum {string}
 */
export const initial_feedback_messages_text = {
    REPORT_PAGE_GET_IT: {
        [LANGUAGES.EN]: ({ title }) => `Hello,
I would like to call upon your services to perform the tasks corresponding to the "${title}" report.
Could you tell me more about that?
Regards.`,
        [LANGUAGES.FR]: ({ title }) => `Bonjour,
J’aimerais faire appel à vos services pour réaliser les tâches correspondant au rapport "${title}".
Pourriez-vous m’en dire plus sur cette prestation?
Cordialement.`,
    },
};


/**
 * texts for tooltips
 * @readonly
 * @enum {string}
 */
export const tooltips_text = {
    MONTHLY_REPORT: {
        [LANGUAGES.EN]: `This button generates a report once a month, based on data from the last 3 months. Example:
<br />On April 6, you will receive a report for the period January 1 to March 31.On May 6, you will receive a report for
<br />the period that has February 1 to April 30, etc.`,
        [LANGUAGES.FR]: `Ce bouton génère un rapport une fois par mois, basé sur les données des 3 derniers mois<br />
écoulés. Exemple : le 6 avril, vous recevrez un rapport sur la période qui va du 1er janvier au 31 mars.  Le 6 mai,
<br />vous recevrez un rapport sur la période qui a du 1er février au 30 avril, etc.`,
    },
    CUSTOM_REPORT: {
        [LANGUAGES.EN]: `In this area, you can perform an audit over the period of your choice.We recommend choosing a
<br />minimum period of one month, to have enough meaningful data.`,
        [LANGUAGES.FR]: `Dans cette zone, vous pouvez réaliser un audit sur la période de votre choix. Nous recommandons
<br />de choisir une période minimale d’un mois, pour avoir assez de données significatives.`,
    },
};

/**
 * texts for btn cookies policy
 * @readonly
 * @constant
 */
export const cookies_policy_text = {
    [LANGUAGES.EN]: 'Cookies policy',
    [LANGUAGES.FR]: 'Politique de cookies',
};

/**
 * texts for report period inputs
 * @readonly
 * @constant
 */
export const dates_text = {
    START_DATE: {
        [LANGUAGES.EN]: 'Start Date',
        [LANGUAGES.FR]: 'Date de début',
    },
    END_DATE: {
        [LANGUAGES.EN]: 'End Date',
        [LANGUAGES.FR]: 'Date de fin',
    },
};

export const not_fount = {
    NOT_FOUND: {
        [LANGUAGES.EN]: 'No result found. Please enter another name.',
        [LANGUAGES.FR]: 'Pas de résultat trouvé. Veuillez saisir un autre nom.',
    },
};

export const months_and_days_names_text = {
    JANUARY: {
        [LANGUAGES.EN]: 'January',
        [LANGUAGES.FR]: 'Janvier',
    },
    FEBRUARY: {
        [LANGUAGES.EN]: 'February',
        [LANGUAGES.FR]: 'Février',
    },
    MARCH: {
        [LANGUAGES.EN]: 'March',
        [LANGUAGES.FR]: 'Mars',
    },
    APRIL: {
        [LANGUAGES.EN]: 'April',
        [LANGUAGES.FR]: 'Avril',
    },
    MAY: {
        [LANGUAGES.EN]: 'May',
        [LANGUAGES.FR]: 'Mai',
    },
    JUNE: {
        [LANGUAGES.EN]: 'June',
        [LANGUAGES.FR]: 'Juin',
    },
    JULY: {
        [LANGUAGES.EN]: 'July',
        [LANGUAGES.FR]: 'Juillet',
    },
    AUGUST: {
        [LANGUAGES.EN]: 'August',
        [LANGUAGES.FR]: 'Août',
    },
    SEPTEMBER: {
        [LANGUAGES.EN]: 'September',
        [LANGUAGES.FR]: 'Septembre',
    },
    OCTOBER: {
        [LANGUAGES.EN]: 'October',
        [LANGUAGES.FR]: 'Octobre',
    },
    NOVEMBER: {
        [LANGUAGES.EN]: 'November',
        [LANGUAGES.FR]: 'Novembre',
    },
    DECEMBER: {
        [LANGUAGES.EN]: 'December',
        [LANGUAGES.FR]: 'Décembre',
    },
    JAN: {
        [LANGUAGES.EN]: 'Jan.',
        [LANGUAGES.FR]: 'Janv.',
    },
    FEB: {
        [LANGUAGES.EN]: 'Feb.',
        [LANGUAGES.FR]: 'Févr.',
    },
    MAR: {
        [LANGUAGES.EN]: 'Mar.',
        [LANGUAGES.FR]: 'Mars',
    },
    APR: {
        [LANGUAGES.EN]: 'Apr.',
        [LANGUAGES.FR]: 'Avril',
    },
    AUG: {
        [LANGUAGES.EN]: 'Aug.',
        [LANGUAGES.FR]: 'Août',
    },
    SEPT: {
        [LANGUAGES.EN]: 'Sept.',
        [LANGUAGES.FR]: 'Sept.',
    },
    OCT: {
        [LANGUAGES.EN]: 'Oct.',
        [LANGUAGES.FR]: 'Oct.',
    },
    NOV: {
        [LANGUAGES.EN]: 'Nov.',
        [LANGUAGES.FR]: 'Nov.',
    },
    DEC: {
        [LANGUAGES.EN]: 'Dec.',
        [LANGUAGES.FR]: 'Déc.',
    },
    MON: {
        [LANGUAGES.EN]: 'Mon',
        [LANGUAGES.FR]: 'Lun',
    },
    TUE: {
        [LANGUAGES.EN]: 'Tue',
        [LANGUAGES.FR]: 'Mar',
    },
    WED: {
        [LANGUAGES.EN]: 'Wed',
        [LANGUAGES.FR]: 'Mer',
    },
    THU: {
        [LANGUAGES.EN]: 'Thu',
        [LANGUAGES.FR]: 'Jeu',
    },
    FRI: {
        [LANGUAGES.EN]: 'Fri',
        [LANGUAGES.FR]: 'Ven',
    },
    SAT: {
        [LANGUAGES.EN]: 'Sat',
        [LANGUAGES.FR]: 'Sam',
    },
    SUN: {
        [LANGUAGES.EN]: 'Sun',
        [LANGUAGES.FR]: 'Dim',
    },
};

export const translate_legend_for_shcedule = {
    AUDIENCE: {
        [LANGUAGES.EN]: ['All conv. value', 'AllConvValue/Cost'],
        [LANGUAGES.FR]: ['Val. tt. les conv.', 'Val. tt. les conv./coût']
    },
    ANALYSIS: {
        [LANGUAGES.EN]: ['products', ''],
        [LANGUAGES.FR]: ['produits', '']
    }
}

export const naming_rmv = {
    AUDIENCE: {
        [LANGUAGES.EN]: [],
        [LANGUAGES.FR]: []
    },
    GLOBAL: {
        [LANGUAGES.EN]: {
            "company_name":'Campaign',
            "clicks":'Clicks',
            "cost":'Cost',
            "impressions":'Impressions',
            "conversions":'Conversions',
            "score":'Score',
        },
        [LANGUAGES.FR]: {
            "company_name":'Campagne',
            "clicks":'Clics',
            "cost":'Coût',
            "impressions":'Impressions',
            "conversions":'Conversions',
            "score":'Score',
        },
    },
    CPC: {
        [LANGUAGES.EN]: ['Keyword', 'First Page CPC', 'Top Of Page CPC', 'Clicks', 'Cost', 'Conversions', 'Impressions'],
        [LANGUAGES.FR]: ['Mots-clés', 'CPC de Première Page', 'Haut De Page CPC', 'Clics', 'Coût', 'Conversions', 'Impressions']
    }
}

export const audience_nameReports = {
    BIG: {
        [LANGUAGES.EN]: 'ADDITIONAL ADJUSTMENTS OF AUCTION',
        [LANGUAGES.FR]: 'AJUSTEMENTS SUPPLÉMENTAIRES DES ENCHÈRES'
    },
    SMALL: {
        [LANGUAGES.EN]: 'Your Customer\'s Behavior and Profile',
        [LANGUAGES.FR]: 'Profils et Comportements de vos Clients'
    }

}

export const analyse_title_translate = {
    BIG: {
         [LANGUAGES.EN]: 'ANALYSIS OF YOUR SHOPPING FLOW',
         [LANGUAGES.FR]: 'ANALYSE DE VOTRE FLUX PRODUITS'
    },
    SMALL: {
        [LANGUAGES.EN]: 'Are your top products visible?',
        [LANGUAGES.FR]: 'Vos top produits sont-ils visibles ?'
    }
   
}

export const potencial_economi = {
    [LANGUAGES.EN]: { 
        saving: 'Potential savings on the 10 keywords filtered on this report : ', 
        total: `Total potential savings on the whole account : `
    },
    [LANGUAGES.FR]: { 
        saving: `Economie potentielle sur les 10 mots-clés filtrés sur ce rapport : `, 
        total: `Economie potentielle totale sur l'ensemble du compte : `
    }
}

export const translateAudit = {
    [LANGUAGES.EN]: {
        AUDIT: 'Audit',
        COMPTE: 'Compte Google :',
        PERIOD: 'Period :',
        TYPE: 'Type compte :',
        KPI: 'KPI :'
    },
    [LANGUAGES.FR]: {
        AUDIT: 'Audit',
        COMPTE: 'Compte Google :',
        PERIOD: 'Période :',
        TYPE: 'Type de compte :',
        KPI: 'KPI :'
    }
}

export const audience_die = {
    [LANGUAGES.EN] : 'This data is displayed for all given devices in all available networks that were listed in current account',
    [LANGUAGES.FR]: 'Ces données sont affichées pour tous les appareils donnés dans tous les réseaux disponibles du compte actuel'
}

export const quality_score_describe = {
    MORE: {
        [LANGUAGES.EN]: 'Well done, your Quality Score is higher than 7',
    [LANGUAGES.FR]: 'Bravo, votre Niveau de Qualité global est supérieur à 7' 
    },
    MIDDLE: {
        [LANGUAGES.EN]: 'Your Average Quality Score',
        [LANGUAGES.FR]: 'Votre Niveau de Qualité moyen'
    }
   
}

export const nav_menu = {
    SCORE: {
        [LANGUAGES.EN]: 'Global Score',
        [LANGUAGES.FR]: 'Note Globale'
},
    GOALS: {
        [LANGUAGES.EN]: 'My goals',
        [LANGUAGES.FR]: 'Mes objectifs'
    }
}

export const quality_score_circels = {
    SCORE: {
        [LANGUAGES.EN]: 'Quality score:',
        [LANGUAGES.FR]: 'Niveau de qualité:'
    },
    CPC: {
        [LANGUAGES.EN]: 'Impact <br/> on CPCs:',
        [LANGUAGES.FR]: 'Impact <br/> sur les CPC:'
    }
}

export const dynamic_comment = {
    TITLE: {
        [LANGUAGES.EN]: 'RECOMMENDATION:',
        [LANGUAGES.FR]: 'RECOMMANDATION:'
    },
    TEXT: {
        [LANGUAGES.EN]: 'Complete your extensions on your campaigns!',
        [LANGUAGES.FR]: 'Complétez vos extensions sur vos campagnes!'
    }
}

export const filtersForReports = {
    KEYWORDS: {
        [LANGUAGES.EN]: 'Keywords',
        [LANGUAGES.FR]: 'Mots-clés'
    },
    PRODUCT: {
        [LANGUAGES.EN]: 'Products',
        [LANGUAGES.FR]: 'Produits'
    },
    KEYWORDSREPORTS: {
        GLOBAL: {
            [LANGUAGES.EN]: 'Research',
            [LANGUAGES.FR]: 'Recherche'
        },
        KEYWORDS: {
            [LANGUAGES.EN]: 'cost',
            [LANGUAGES.FR]: 'coût'
        }
    }
}

export const keywordsTableForColor = {
    KEYWORDS: {
        [LANGUAGES.EN]: 'Keywords',
        [LANGUAGES.FR]: 'Mots-clés',
    },
    KEYWORDS_WORDS: {
        ROAS: {
            [LANGUAGES.EN]:'ROAS',
            [LANGUAGES.FR]: 'moyenne'
        },
        COST: {
            [LANGUAGES.EN]: 'Cost',
            [LANGUAGES.FR]: 'Coût'
        }
    },
    NONBARDER: {
        [LANGUAGES.EN]: 'Non-branded',
        [LANGUAGES.FR]: 'marque'
    },
    PRODUCTS: {
        [LANGUAGES.EN]: 'Products',
        [LANGUAGES.FR]: 'Produits'
    },
    PRODUCT_KEYWORDS: {
        AVERAGE: {
            [LANGUAGES.EN]: 'average',
            [LANGUAGES.FR]: 'inférieur'
        },
        HIGH: {
            [LANGUAGES.EN]: 'high',
            [LANGUAGES.FR]: 'élevé'
        },
        GOOD: {
            [LANGUAGES.EN]: 'good',
            [LANGUAGES.FR]: 'bon'
        }
    }
}

export const emptyData = {
    [LANGUAGES.EN]: `No data for displaying.<br> You need to choose another<br> period of time.`,
    [LANGUAGES.FR]: `Le niveau de qualité n'est<br> pas disponible lorsque la<br> campagne est posée. Nous<br> sommes désolés !`
}

export const translateForSchedule = {
    AUDIENCE: {
        AGE: {
             [LANGUAGES.EN]: ['18-24', '25-34', '35-44', '45-54', '55-64', '65 or more', 'Undetermined'],
             [LANGUAGES.FR]: ['18-24', '25-34', '35-44', '45-54', '55-64', '65 ou plus', 'Indéterminé']
        },
        DEVICE: {
            [LANGUAGES.EN]: ['Computers', 'Mobile', 'Tablets'],
            [LANGUAGES.FR]: ['Ordinateurs', 'Mobile', 'Tablettes']
        },
        GENDER: {
            [LANGUAGES.EN]: ['Female', 'Male', 'Undetermined'],
            [LANGUAGES.FR]: ['Femme', 'Homme', 'Indéterminé']
        },
        HOURS: {
            [LANGUAGES.EN]: ['0','1', '2', '3', '4', '5', '10', '11','18'],
            [LANGUAGES.FR]: ['0','1', '2', '3', '4', '5', '10', '11','18']
        },
        REGIONS: {
            [LANGUAGES.EN]: ['Undetermined', 'Ile-de-Fra', 'Provence-A', 'Occitanie', 'Auvergne-R', 'Nouvelle-A', 'Grand Est'],
            [LANGUAGES.FR]: ['Undetermined', 'Ile-de-Fra', 'Provence-A', 'Occitanie', 'Auvergne-R', 'Nouvelle-A', 'Grand Est']
        },
        DAYS: {
            [LANGUAGES.EN]: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            [LANGUAGES.FR]: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
        },
    },
    ANALYSIS: {
        [LANGUAGES.EN]: (number, index) => {
            if (index === 0) return  `(${number}%)|Products in the feed`;
            if( index === 1) return  number === '' 
            ? `No access to Merchant Center|configure your Merchant Center|in order to have data here.`
            : `(${number}%) | Disapproved products `;
            if (index === 2) return  `(${number}%)|Products without impressions`;
            if (index === 3) return `(${number}%)|Products without clicks`
        },
        [LANGUAGES.FR]: (number, index) => {
            if (index === 0) return  `(${number}%)|Produits dans le flux`;
            if( index === 1) return  number === '' 
            ? `Pas d'accès au Merchant Center|configurer votre Merchant Center|pour avoir des données ici.`
            : `(${number}%) | Produits refusés `;
            if (index === 2) return  `(${number}%)|Produits sans impressions`;
            if (index === 3) return `(${number}%)|Produits sans clics`
        }
    }
}

export const translateAroundSchedule = {
    LEGEND: {
        [LANGUAGES.EN]: ['Products in the feed', 'Products without clicks'],
        [LANGUAGES.FR]: ['Produits dans le flux', 'Produits sans clics']
    },
    DESCRIBE: {
        [LANGUAGES.EN]: ['of your products gather dust'],
        [LANGUAGES.FR]: ['de vos produits prennent la poussière']
    }
}

export const translateTotal = {
    CAMPAINGS: {
        [LANGUAGES.EN]: 'Total: all campaigns',
        [LANGUAGES.FR]: 'Total : de toutes les campagnes'
    },
    KEYWORDS: {
        [LANGUAGES.EN]: 'Total: all filtered keywords',
        [LANGUAGES.FR]: 'Total : tous les mots-clés filtrés'
    }
}

export const badScoreTranslate = {
    TITLE: {
        [LANGUAGES.EN]: 'You need our assistance',
        [LANGUAGES.FR]: 'Vous avez besoin d\'assistance'
    },
    TEXT: {
        FIRST_OFFER: {
            [LANGUAGES.EN]: 'Your score on this slide is much lower, than overall on account.',
            [LANGUAGES.FR]: 'Votre scoring sur cette slide est beaucoup plus faible que dans l\'ensemble sur le compte.'
        },
        SECOND_OFFER: {
            [LANGUAGES.EN]: 'It seems that you are having trouble with ...',
            [LANGUAGES.FR]: 'Il semble que vous ayez des problèmes avec...'
        },
        THIRD_OFFER: {
            [LANGUAGES.EN]:'Use',
            [LANGUAGES.FR]:'Veuillez à'
        },
        FOURTH_OFFER: {
            [LANGUAGES.EN]:'to improve your efficiency. In case you have any questions on your report,',
            [LANGUAGES.FR]:'pour améliorer votre efficacité. Si vous avez des questions par rapport à votre rapport,'
        },
        FIFTH_OFFER: {
            [LANGUAGES.EN]: '.Otherwise in order to get',
            [LANGUAGES.FR]: 'Sinon afin d\'obtenir de nouvelles'
        },
        SIXTH_OFFER: {
            [LANGUAGES.EN]: ' new information,',
            [LANGUAGES.FR]: ' informations,'
        },
        SEVETH_OFFER: {
            [LANGUAGES.EN]: 'and we will help you!',
            [LANGUAGES.FR]: 'pour obtenir un retour immédiat !'
        }
    },
    LINKS: {
        TIPS: {
            [LANGUAGES.EN]: 'our tips',
            [LANGUAGES.FR]: 'consulter nos conseils'
        },
        ZENDESK: {
            [LANGUAGES.EN]: 'contact our wookie',
            [LANGUAGES.FR]: 'contactez notre Wookie '
        },
        CONTACTUS: {
            [LANGUAGES.EN]: 'contact us',
            [LANGUAGES.FR]: 'contactez-nous'
        }
    }
}

export const coloringTabels = {
    ECONOMY: {
        [LANGUAGES.EN]: ['Products with a lower than average ROAS', 'Keywords with a lower than average ROAS', 'Mots-clés avec un ROAS inférieur à la moyenne', 'Produits avec un ROAS inférieur à la moyenne']
    },
    VALUECOST: {
        [LANGUAGES.EN]: ['Non-branded keywords with a good ROAS', 'Mots-clés hors marque avec un bon ROAS', 'Products with a good ROAS', 'Produits avec un bon ROAS']
    }
}

export const naminColumns = {
    [LANGUAGES.EN]: ['Total: all campaigns', 'Total: all filtered keywords', 'Total: all filtered products', 'Total : de toutes les campagnes', 'Total : tous les mots-clés filtrés']
}

export const TranslateForTable = {
    KEYWORDS: {
        COST: {
            [LANGUAGES.EN]: [
                'Keyword',
                'Campaign',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Impr. (Top) %',
                'Cost',
                'All conv.',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost',
                'Quality score'
            ],
            [LANGUAGES.FR]: [
                'Mots-clés',
                'Campagne',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Impr. (Top) %',
                'Coût',
                'Tt. les conv.',
                'Coût / toutes les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût',
                'Niveau de Qualité'
            ]
        },
        ROAS: {
            [LANGUAGES.EN]: [
                'Keyword',
                'Campaign',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Impr. (Top) %',
                'Cost',
                'All conv.',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost',
                'Potential Economy',
                'Quality score'
            ],
            [LANGUAGES.FR]: [
                'Mots-clés',
                'Campagne',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Impr. (Top) %',
                'Coût',
                'Tt. les conv.',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût',
                'Economie potentielle',
                'Niveau de Qualité'
            ]
        },
        NONBARDER: {
            [LANGUAGES.EN]: [
                'Keyword',
                'Campaign',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Impr. (Top) %',
                'Impr. (Abs. Top) %',
                'Cost',
                'All conv.',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost',
                'Quality score'
            ],
            [LANGUAGES.FR]: [
                'Mots-clés',
                'Campagne',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Impr. (Top) %',
                'Impr. (Abs. Top) %',
                'Coût',
                'Tt. les conv.',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût',
                'Niveau de Qualité'
            ]
        },
        AVERAGE_CPA: {
            [LANGUAGES.EN]: [
                'Keyword',
                'Campaign',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Impr. (Top) %',
                'Cost',
                'All conv.',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost',
                'Potential Economy',
                'Quality score' 
            ],
            [LANGUAGES.FR]: [
                'Mots-clés',
                'Campagne',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Impr. (Top) %',
                'Coût',
                'Tt. les conv.',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût',
                'Economie potentielle',
                'Niveau de Qualité'
            ]
        }
    },
    PRODUCTS: {
        COST: {
            [LANGUAGES.EN]: [
                'Products',
                'Campaign',
                'Clicks',
                'Impressions',
                'Avg. CPC',
                'Impr. (abs. top)%',
                'Cost',
                'Conversions',
                'Cost / conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Produits',
                'Campagne',
                'Clics',
                'Impressions',
                'CPC moy.',
                'Impr. (abs. top)%',
                'Coût',
                'Conversions',
                'Coût / conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        },
        AVERAGE: {
            [LANGUAGES.EN]: [
                'Products',
                'Campaign',
                'Clicks',
                'Impressions',
                'Avg. CPC',
                'Impr. (abs. top)%',
                'Cost',
                'Conversions',
                'Cost / conv.',
                'All conv. value',
                ' All Conv Value/Cost',
                'Potential Economy'
            ],
            [LANGUAGES.FR]: [
                'Produits',
                'Campagne',
                'Clics',
                'Impressions',
                'CPC moy.',
                'Impr. (abs. top)%',
                'Coût',
                'Conversions',
                'Coût / conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût',
                'Economie potentielle'
            ]
        },
        GOOD: {
            [LANGUAGES.EN]: [
                'Products',
                'Campaign',
                'Clicks',
                'Impressions',
                'Avg. CPC',
                'Impr. (abs. top)%',
                'Cost',
                'Conversions',
                'Cost / conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Produits',
                'Campagne',
                'Clics',
                'Impressions',
                'CPC moy.',
                'Impr. (abs. top)%',
                'Coût',
                'Conversions',
                'Coût / conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    },
    GROWTH: {
        RESEARCH: {
            [LANGUAGES.EN] : [
                'Campaign',
                'Search Impr. share',
                'Search Lost IS (budget)',
                'Search Lost IS (rank)',
                'Search lost top IS (rank)',
                'Cost',
                'ROAS',
                'CPA'
            ],
          [LANGUAGES.FR]:  [
                'Campagne',
                'Tx d’impr',
                'Tx d’impr. perdu (budget)',
                'Tx d’impr. perdu (classement)',
                `Tx d'impr. perdu en haut de p. (class.)`,
                'Coût',
                'ROAS',
                'CPA'
            ]
        },
        SHOPPING: {
            [LANGUAGES.EN]: [
                'Campaign',
                'Search Impr. share',
                'Search Lost IS (budget)',
                'Search Lost IS (rank)',
                'Click share',
                'Cost',
                'ROAS'
            ],
            [LANGUAGES.FR]: [
                'Campagne',
                'Tx d’impr',
                'Tx d’impr. perdu (budget)',
                'Tx d’impr. perdu (classement)',
                'Part de clics',
                'Coût',
                'ROAS'
            ]
        }
    },
    AGE: {
        CONTENT: {
            [LANGUAGES.EN]: [
                'Age Range',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Cost',
                'Conversions',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Age Range',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Coût',
                'Conversions',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    },
    REGIONS: {
        CONTENT: {
            [LANGUAGES.EN]: [
                'Regions France',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Cost',
                'All conv.',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Régions France',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Coût',
                'Tt. les conv.',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    },
    DEVICE: {
        CONTENT: {
            [LANGUAGES.EN]: [
                'Device',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Cost',
                'Conversions',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Appareil',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Coût',
                'Conversions',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    },
    GENDER: {
        CONTENT: {
            [LANGUAGES.EN]: [
                'Gender',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Cost',
                'Conversions',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Sexe',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Coût',
                'Conversions',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    },
    HOURS: {
        CONTENT: {
            [LANGUAGES.EN]: [
                'Hour of day',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Cost',
                'Conversions',
                'Cost / conv.',
                'Total conv. value',
                'TotalConvValue/Cost',
                'All conv.',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Heures de la journée',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Coût',
                'Conversions',
                'Coût / conv.',
                'Valeur totale de conv.',
                'ValeurTotaleConv/ Coût',
                'Tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    },
    DAYS: {
        CONTENT: {
            [LANGUAGES.EN]: [
                'Day of week',
                'Clicks',
                'Impressions',
                'CTR',
                'Avg. CPC',
                'Cost',
                'Conversions',
                'Cost / all conv.',
                'All conv. value',
                ' All Conv Value/Cost'
            ],
            [LANGUAGES.FR]: [
                'Jours de la semaine',
                'Clics',
                'Impressions',
                'CTR',
                'CPC moy.',
                'Coût',
                'Conversions',
                'Coût/tt. les conv.',
                'Val. tt. les conv.',
                'Val. tt. les conv./coût'
            ]
        }
    }
}