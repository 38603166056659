/** @module reducers/common/lang */

import { createReducer } from '@reduxjs/toolkit';
// import store from 'src/config/store';
import { LANGUAGES, ACTION_TYPES, REDUCERS_NAMES, LOCAL_STORAGE } from 'src/config/constants';

import {
    get_messages,
    get_subscription_data,
    get_report_page_template,
    get_report_pages_in_background,
    update_report_form,
} from '../account/actions';
import {
    get_main,
    get_faqs,
    get_legal_notice,
    get_privacy_policy,
    get_offices,
    get_solutions,
    get_tariffs,
} from '../lending/actions';
/**
 * LANG ACTION HANDLER
 * @type {function}
 */
export const setLang = (lang) => (dispatch, getState) => {
    // Step 1: changing lang
    dispatch({
        type: ACTION_TYPES.SET_LANG,
        payload: {
            data: {
                lang,
            },
        },
    });
    // Step 2: updating reducers by re-sending requests again with other language
    const globalState = getState();
    const actions = {
        [REDUCERS_NAMES.MAIN]: get_main,
        [REDUCERS_NAMES.TARIFFS]: get_tariffs,
        [REDUCERS_NAMES.OFFICES]: get_offices,
        [REDUCERS_NAMES.LEGAL_NOTICE]: get_legal_notice,
        [REDUCERS_NAMES.PRIVACY_POLICY]: get_privacy_policy,
        [REDUCERS_NAMES.SOLUTIONS]: get_solutions,
        [REDUCERS_NAMES.FAQS]: get_faqs,
    };
    if (localStorage.getItem(LOCAL_STORAGE.TOKEN_ACCESS)) {
        actions[REDUCERS_NAMES.ACCOUNT_MESSAGES] = get_messages;
        actions[REDUCERS_NAMES.ACCOUNT_SUBSCRIPTION_DATA] = get_subscription_data;
        actions[REDUCERS_NAMES.REPORT_TEMPLATES] = get_report_page_template;
        actions[REDUCERS_NAMES.REPORT_PAGES] = get_report_pages_in_background;
        actions[REDUCERS_NAMES.REPORT_FORM] = update_report_form;
    }
    Object.keys(globalState).forEach((storage_key) => {
        const storage_val = globalState[storage_key];
        if (storage_val.is_fetched) {
            if (storage_key in actions) {
                dispatch(actions[storage_key](storage_val.data));
            }
        }
    });
};

/**
 * lang INITIAL STATE
 * @constant
 * @type {object}
 * @default
 */
export const initialState = {
    lang: LANGUAGES.DEFAULT,
};

/**
 * Lang Reducer
 */
export default createReducer(initialState, {
    [ACTION_TYPES.SET_LANG]: (state, action) => ({
        ...state,
        ...action.payload.data,
    }),
});
