/** @module config/constants */

/**
 * Steps of XHR
 * @readonly
 * @enum {function}
 * @static
 */
const XHR_STEPS = {
    DO_NOTHING: (actionType) => `${actionType}_DO_NOTHING`,
    RESET: (actionType) => `${actionType}_RESET`,
    REQUEST: (actionType) => `${actionType}_PENDING`,
    SUCCESS: (actionType) => `${actionType}_FULFILLED`,
    FAILURE: (actionType) => `${actionType}_REJECTED`,
};

/**
 * Reducer's names
 * @readonly
 * @static
 * @enum {string}
 */
const REDUCERS_NAMES = {
    // LENDING APP
    MAIN: 'main',
    LANG: 'lang',
    SOLUTIONS: 'solutions',
    TARIFFS: 'tariffs',
    FAQS: 'faqs',
    OFFERS: 'offers',
    LEGAL_NOTICE: 'legal_notice',
    PRIVACY_POLICY: 'privacy_policy',
    OFFICES: 'offices',
    FEEDBACK: 'feedback',
    // ACCOUNT APP
    ACCOUNT: 'auth',
    REFRESH_TOKEN: 'token',
    ACCOUNT_MESSAGES: 'account_messages',
    ACCOUNT_ACCOUNTS: 'account_accounts',
    ACCOUNT_SUBSCRIPTION_DATA: 'account_subscription_data',
    REPORT_CREATE: 'report_create',
    REPORT_LIST: 'reports_list',
    REPORT_PAGES: 'report_pages',
    REPORT_FORM: 'report_form',
    REPORT_TEMPLATES: 'report_templates',
    REPORT_PDFS: 'report_pdfs',
    REPORT_PAGE_MARK: 'report_mark',
    REPORT_DATA: 'all_reports'
};

/**
 * Types of buttons
 * @readonly
 * @enum {string}
 * @static
 */
const BUTTONS_TYPES = {
    AUDIT: 'BA',
    APPOINTMENT: 'BAP',
    QUOTE: 'BQ',
};

/**
 * ID's of pages (must equals to items from DB)
 * @readonly
 * @enum {string}
 * @static
 */
const PAGES_IDS = {
    CONTACT: 'CONTACT_US',
    PRICING: 'PRICING',
    LEGAL_NOTICE: 'LEGAL_NOTICE',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    FAQ: 'FAQ',
    OFFER: 'OFFER',
    TRAINING: 'TRAINING',
};

/**
 * part of paths of APIs of some apps
 * @readonly
 * @enum {string}
 */
const API_APPS = {
    LENDING: 'lending',
    ACCOUNT: 'account',
};

/**
 * APIs
 * @readonly
 * @enum {object}
 * @property {object} POST_FEEDBACK api of creating feedback
 * @property {object} GET_MAIN api of main data
 * @property {object} GET_SOLUTIONS api of solutions data
 * @property {object} GET_FAQS api of FAQs data
 * @property {object} GET_TARIFFS api of tariffs data
 * @property {object} GET_LEGAL_NOTICE api of legal notice data
 * @property {object} GET_PRIVACY_POLICY api of PRIVACY POLICY data
 * @property {object} GET_OFFICES api of contact data
 * @property {object} LOGIN api of login by google
 * @property {object} REFRESH_TOKEN api of refreshing of token
 * @property {object} GET_ACCOUNT_DATA api of fetching account data
 * @property {object} LOGOUT api of contact data
 * @property {object} GET_ACCOUNTS api of fetching accounts from google
 * @property {object} POST_CHECK_ACCOUNT api of checking has account campains or not
 * @property {object} POST_STATUS_MONTHLY_REPORT api for changing status of monthly report in google account
 * @property {object} GET_MESSAGES api of fetching alerts
 * @property {object} POST_REPORT_FORM api of updatating charts
 * @property {object} GET_REPORTS api of fetching reports list
 * @property {object} GET_REPORT api of fetching report by id
 * @property {object} DELETE_REPORT api of deleting report by id
 * @property {object} GRANT_GOOGLE_MCC_ACCESS
 * @property {object} POST_REPORT api of creataing report and sending to email
 * @property {object} GET_SUBSCRIPTION_DATA api of fetching subscription data
 * @property {object} GET_REPORT_PAGES api of fetching html reports
 * @property {object} POST_REPORT_EMAIL api of sending report to email
 * @property {object} GET_REPORT_PAGE_TEMPLATE
 * @property {object} GET_REPORT_PDF
 * @property {object} GET_REPORT_PAGES
 * @property {object} POST_REPORT_PAGE_MARK
 */
const API = {
    // LENDING APP
    POST_FEEDBACK: {
        url: `/${API_APPS.LENDING}/create-feedback/`,
        method: 'POST',
    },
    GET_MAIN: {
        url: `/${API_APPS.LENDING}/main/`,
        method: 'GET',
    },
    GET_SOLUTIONS: {
        url: `/${API_APPS.LENDING}/solutions/`,
        method: 'GET',
    },
    GET_FAQS: {
        url: `/${API_APPS.LENDING}/faqs/`,
        method: 'GET',
    },
    GET_TARIFFS: {
        url: `/${API_APPS.LENDING}/tariffs/`,
        method: 'GET',
    },
    GET_LEGAL_NOTICE: {
        url: `/${API_APPS.LENDING}/legal-notice/`,
        method: 'GET',
    },
    GET_PRIVACY_POLICY: {
        url: `/${API_APPS.LENDING}/privacy-policy/`,
        method: 'GET',
    },
    GET_OFFICES: {
        url: `/${API_APPS.LENDING}/offices/`,
        method: 'GET',
    },
    // ACCOUNT APP
    LOGIN: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/login/`,
        method: 'POST',
    },
    REFRESH_TOKEN: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/refresh/`,
        method: 'POST',
    },
    GET_ACCOUNT_DATA: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/account/`,
        method: 'GET',
    },
    LOGOUT: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/logout/`,
        method: 'POST',
    },
    GET_ACCOUNTS: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/accounts/`,
        method: 'GET',
    },
    GET_MESSAGES: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/messages/`,
        method: 'GET',
    },
    POST_REPORT_FORM: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/update-report-form/`,
        method: 'POST',
    },
    POST_CHECK_ACCOUNT: ({ id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/check-account/${id}/`,
        method: 'POST',
    }),
    POST_STATUS_MONTHLY_REPORT: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/status-monthly-report/`,
        method: 'POST',
    },
    POST_REPORT: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/`,
        method: 'POST',
    },
    GET_REPORTS: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/`,
        method: 'GET',
    },
    GET_REPORT: ({ id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/${id}/`,
        method: 'GET',
    }),
    GET_REPORT_PAGES: ({ id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/${id}/pages/`,
        method: 'GET',
    }),
    GET_REPORT_PDF: ({ id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/${id}/pdf/`,
        method: 'GET',
        responseType: 'blob',
    }),
    DELETE_REPORT: ({ id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/${id}/`,
        method: 'DELETE',
    }),
    GRANT_GOOGLE_MCC_ACCESS: ({ reports_group_id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/mcc-access/${reports_group_id}/`,
        method: 'POST',
    }),
    GET_REPORT_PAGE_TEMPLATE: ({ id, slug, format = '' }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/${id}/pages/${slug}/${format}`,
        method: 'GET',
    }),
    POST_REPORT_EMAIL: ({ id }) => ({
        // DONE
        url: `/${API_APPS.ACCOUNT}/reports/${id}/send_email/`,
        method: 'POST',
    }),
    PUT_REPORT_EMAIL: ({ pdf,name,email,account_name }) => ({
        // DONE
        url: `${API_APPS.ACCOUNT}/upload/${name}/`,
        method: 'PUT',
        data:pdf,
        params:{"email":email,"account_name":account_name},
        headers: {'Content-Type': 'application/json' }

    }),
    GET_SUBSCRIPTION_DATA: {
        // DONE
        url: `/${API_APPS.ACCOUNT}/subscription/`,
        method: 'GET',
    },
    POST_REPORT_PAGE_MARK: ({ id, slug }) => ({
        url: `/${API_APPS.ACCOUNT}/reports/${id}/pages/${slug}/mark/`,
        method: 'POST',
    }),
    GET_ALL_REPORTS_DATA: (id, lang = 'en') => ({
        url: `/${API_APPS.ACCOUNT}/reports/${id}/pdf-data/`,
        method: 'GET',
        params: {
            lang:lang
        }
    })
};

/**
 * @readonly
 * @enum {string}
 * @property {string} POST_FEEDBACK action type of creating feedback
 * @property {string} GET_MAIN action type of fetching main data
 * @property {string} GET_SOLUTIONS action type of fetching solutions data
 * @property {string} GET_FAQS action type of fetching FAQs data
 * @property {string} GET_TARIFFS action type of fetching tariffs data
 * @property {string} GET_LEGAL_NOTICE action type of fetching leggal notice data
 * @property {string} GET_PRIVACY_POLICY action type of fetching PRIVACY POLICY data
 * @property {string} GET_OFFICES action type of fetching contact data
 * @property {string} LOGIN action type of login by google
 * @property {string} REFRESH_TOKEN action type of refreshing of token
 * @property {string} GET_ACCOUNT_DATA action type of fetching account data
 * @property {string} LOGOUT action type of logout
 * @property {string} GET_ACCOUNTS action type of fetching accounts from google
 * @property {string} POST_CHECK_ACCOUNT
 * @property {string} POST_STATUS_MONTHLY_REPORT
 * @property {string} GET_MESSAGES action type of fetching alerts
 * @property {string} GET_SUBSCRIPTION_DATA action type of fetching subscription data
 * @property {string} POST_REPORT_FORM action type of update charts
 * @property {string} POST_REPORT action type of creataing report and sending to email
 * @property {string} GET_REPORTS action type of fetching reports list
 * @property {string} GET_REPORT_BY_ID action type of fetching report by id
 * @property {string} DELETE_REPORT_BY_ID action type of deleting report by id
 * @property {string} GRANT_GOOGLE_MCC_ACCESS
 * @property {string} GET_REPORT_PAGE_TEMPLATE
 * @property {string} GET_REPORT_PAGES action type of fetching html report
 * @property {string} POST_REPORT_EMAIL api of sending report to email
 * @property {string} GET_REPORT_PAGES_BACK action type of fetching html report in background
 * @property {string} POST_REPORT_PAGE_MARK action type of adding mark to report page
 */
const ACTION_TYPES = {
    // LENDING APP
    POST_FEEDBACK: `${REDUCERS_NAMES.FEEDBACK}/POST_FEEDBACK`,
    GET_MAIN: `${REDUCERS_NAMES.MAIN}/GET_MAIN`,
    GET_SOLUTIONS: `${REDUCERS_NAMES.SOLUTIONS}/GET_SOLUTIONS`,
    GET_FAQS: `${REDUCERS_NAMES.FAQS}/GET_FAQS`,
    GET_TARIFFS: `${REDUCERS_NAMES.TARIFFS}/GET_TARIFFS`,
    GET_LEGAL_NOTICE: `${REDUCERS_NAMES.LEGAL_NOTICE}/GET_LEGAL_NOTICE`,
    GET_PRIVACY_POLICY: `${REDUCERS_NAMES.PRIVACY_POLICY}/GET_PRIVACY_POLICY`,
    GET_OFFICES: `${REDUCERS_NAMES.OFFICES}/GET_OFFICES`,
    SET_LANG: `${REDUCERS_NAMES.LANG}/SET_LANG`,
    // ACCOUNT APP
    LOGIN: `${REDUCERS_NAMES.ACCOUNT}/LOGIN`,
    REFRESH_TOKEN: `${REDUCERS_NAMES.REFRESH_TOKEN}/REFRESH_TOKEN`,
    GET_ACCOUNT_DATA: `${REDUCERS_NAMES.ACCOUNT}/GET_ACCOUNT_DATA`,
    LOGOUT: `${REDUCERS_NAMES.ACCOUNT}/LOGOUT`,
    GET_ACCOUNTS: `${REDUCERS_NAMES.ACCOUNT_ACCOUNTS}/GET_ACCOUNTS`,
    POST_CHECK_ACCOUNT: `${REDUCERS_NAMES.ACCOUNT_ACCOUNTS}/POST_CHECK_ACCOUNT`,
    POST_STATUS_MONTHLY_REPORT: `${REDUCERS_NAMES.ACCOUNT_ACCOUNTS}/POST_STATUS_MONTHLY_REPORT`,
    GET_MESSAGES: `${REDUCERS_NAMES.ACCOUNT_MESSAGES}/GET_MESSAGES`,
    GET_SUBSCRIPTION_DATA: `${REDUCERS_NAMES.ACCOUNT_SUBSCRIPTION_DATA}/GET_SUBSCRIPTION_DATA`,
    POST_REPORT_FORM: `${REDUCERS_NAMES.REPORT_FORM}/POST_REPORT_FORM`,
    POST_REPORT: `${REDUCERS_NAMES.REPORT_CREATE}/POST_REPORT`,
    GET_REPORTS: `${REDUCERS_NAMES.REPORT_LIST}/GET_REPORTS`,
    GET_REPORT_BY_ID: `${REDUCERS_NAMES.REPORT_LIST}/GET_REPORT_BY_ID`,
    DELETE_REPORT_BY_ID: `${REDUCERS_NAMES.REPORT_LIST}/DELETE_REPORT_BY_ID`,
    GRANT_GOOGLE_MCC_ACCESS: `${REDUCERS_NAMES.REPORT_LIST}/GRANT_GOOGLE_MCC_ACCESS`,
    GET_REPORT_PAGE_TEMPLATE: `${REDUCERS_NAMES.REPORT_TEMPLATES}/GET_REPORT_PAGE_TEMPLATE`,
    GET_REPORT_PAGES: `${REDUCERS_NAMES.REPORT_PAGES}/GET_REPORT_PAGES`,
    GET_REPORT_PDF: `${REDUCERS_NAMES.REPORT_PAGES}/GET_REPORT_PDF`,
    POST_REPORT_EMAIL: `${REDUCERS_NAMES.REPORT_LIST}/POST_REPORT_EMAIL`,
    PUT_REPORT_EMAIL: `${REDUCERS_NAMES.REPORT_LIST}/PUT_REPORT_EMAIL`,
    GET_REPORT_PAGES_BACK: `${REDUCERS_NAMES.REPORT_PAGES}/GET_REPORT_PAGES_BACK`,
    POST_REPORT_PAGE_MARK: `${REDUCERS_NAMES.REPORT_PAGE_MARK}/POST_REPORT_PAGE_MARK`,
    GET_ALL_REPORTS_DATA: `${REDUCERS_NAMES.REPORT_DATA}/GET_ALL_REPORTS_DATA`
};

/**
 * Languages
 * @readonly
 * @enum {string}
 * @static
 */
const LANGUAGES = {
    EN: 'en',
    FR: 'fr',
    DEFAULT: 'fr',
};

/**
 * Statuses
 * @readonly
 * @enum {number}
 * @static
 */
const STATUSES = {
    STATUS_200: 200,
    STATUS_201: 201,
    STATUS_400: 400,
};

/**
 * names of fields of form
 * @readonly
 * @enum {string}
 * @static
 */
const FORM_INPUTS = {
    ACCOUNT_ID: 'account_id',
    NAME: 'name',
    EMAIL: 'email',
    WEB_SITE: 'company_website',
    PHONE: 'phone',
    MESSAGE: 'message',
};

/**
 * theme of tarrifs
 * @readonly
 * @enum {string}
 * @static
 */
const TARIFFS_THEME = {
    LIGHT: 'light',
    DARK: 'dark',
    DEFAULT: 'dark',
};

/**
 * static pages slugs
 * @readonly
 * @enum {object}
 * @static
 * @property {function} REPORT
 */
const STATIC_PAGES_SLUGS = {
    CREATE_REPORT: {
        [LANGUAGES.EN]: 'create-report',
        [LANGUAGES.FR]: 'creer-un-rapport',
    },
    MY_DASHBOARD: {
        [LANGUAGES.EN]: 'dashboard',
        [LANGUAGES.FR]: 'tableau-de-bord',
    },
    SUBSCRIPTION: {
        [LANGUAGES.EN]: 'subscription',
        [LANGUAGES.FR]: 'abonnement',
    },
    REPORT: (report_id = ':report_id') => ({
        [LANGUAGES.EN]: `report${report_id ? `/${report_id}` : ''}`,
        [LANGUAGES.FR]: `rapport${report_id ? `/${report_id}` : ''}`,
    }),
};

/**
 * apps paths
 * @readonly
 * @enum {object}
 * @static
 */
const APPS_PATHS = {
    LENDING: {
        [LANGUAGES.EN]: '',
        [LANGUAGES.FR]: '',
    },
    ACCOUNT: {
        [LANGUAGES.EN]: 'dashboard',
        [LANGUAGES.FR]: 'tableau-de-bord',
    },
};

/**
 * names of fields of create report form
 * @readonly
 * @enum {string}
 * @static
 */
const FORM_CREATE_REPORT_INPUTS = {
    KPI: 'kpi',
    ACCOUNT: 'account_type_id',
    EMAIL: 'email',
    PERIOD_FROM: 'period_from',
    PERIOD_TO: 'period_to',
    SEND_REPORT_ON_EMAIL: 'is_send_report',
};

/**
 * types of sorting
 * @readonly
 * @enum {string}
 * @static
 */
const SORTING_TYPES = {
    BY_ID: 1,
    BY_LARGER_TO_SMALLER: 2,
    BY_SMALLER_TO_LARGER: 3,
    BY_NANE: 4,
    BY_CREATED_AT_LARGER_TO_SMALLER: 5,
    BY_CREATED_AT_SMALLER_TO_LARGER: 6,
    BY_OUTER_ID: 7,
    BY_ACCOUNT: 8,
    BY_CREATED_AT: 9,
    BY_PERIOD: 10,
    BY_KPI: 11,
    BY_ACCOUNT_TYPE: 12,
};

/**
 * localStorage keys
 * @readonly
 * @static
 * @enum {string}
 */
const LOCAL_STORAGE = {
    TOKEN_ACCESS: 'TOKEN_ACCESS',
    TOKEN_REFRESH: 'TOKEN_REFRESH',
    SELECTED_ACCOUNT_ID: 'SELECTED_ACCOUNT_ID',
    SELECTED_ACCOUNT_NAME: 'SELECTED_ACCOUNT_NAME',
};

/**
 * headers keys
 * @readonly
 * @static
 * @enum {string}
 */
const HEADERS = {
    AUTHORIZATION: 'Authorization',
    ACCOUNT_ID: 'Account-Id',
    ACCEPT_LANGUAGE: 'Accept-Language',
    CACHE_CONTROL: 'Cache-Control',
};

/**
 * marks of report page
 * @readonly
 * @static
 * @enum {string}
 */
const REPORT_PAGE_MARK = {
    GOOD: 'GOOD',
    NORMAL: 'NORMAL',
    BAD: 'BAD',
};

export {
    REDUCERS_NAMES,
    HEADERS,
    LOCAL_STORAGE,
    SORTING_TYPES,
    FORM_CREATE_REPORT_INPUTS,
    APPS_PATHS,
    STATIC_PAGES_SLUGS,
    TARIFFS_THEME,
    FORM_INPUTS,
    STATUSES,
    LANGUAGES,
    ACTION_TYPES,
    API,
    API_APPS,
    PAGES_IDS,
    BUTTONS_TYPES,
    XHR_STEPS,
    REPORT_PAGE_MARK,
};
