import auth from './account/auth';
import {
    account_messages,
    account_subscription_data,
    account_accounts,
    report_form,
    reports_list,
    report_pages,
    report_create,
    token,
    report_templates,
    report_pdfs,
    pdfReport,
    get_list_reports
} from './account/reducers';
import lang from './common/lang';
import feedback from './lending/feedback';
import main from './lending/main';
import { solutions, legal_notice, privacy_policy, offices, faqs, tariffs } from './lending/reducers';

export {
    get_list_reports,
    lang,
    auth,
    main,
    feedback,
    solutions,
    legal_notice,
    privacy_policy,
    offices,
    faqs,
    tariffs,
    account_messages,
    account_subscription_data,
    account_accounts,
    report_form,
    reports_list,
    report_pages,
    report_create,
    token,
    report_templates,
    report_pdfs,
    pdfReport,
};
