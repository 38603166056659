import React from 'react';

import { Container } from 'src/components/layouts/components';
import { MEDIAURL } from 'src/config/AxiosInterceptor';
import { page_header, page_header_title, page_header_description } from 'src/styles/Common.module.scss';

import BreadCrumbs from './BreadCrumbs';
import RandomAlert from './RandomAlert';

const PageHeader = React.memo(({ title, description, bg_src, messages, breadcrumbs, white, transparent }) => {
    return (
        <div
            className={page_header}
            style={{
                ...(bg_src ? { backgroundImage: `url(${MEDIAURL}${bg_src})` } : {}),
                ...(white ? { backgroundColor: 'white' } : {}),
                ...(transparent ? { backgroundColor: 'transparent' } : {}),
            }}>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
            <div className={page_header_title}>{title}</div>
            <div className={page_header_description}>{description}</div>
            <Container>
                <RandomAlert alerts={messages} />
            </Container>
        </div>
    );
});

export default PageHeader;
