import React from 'react';

import dompurify from 'dompurify';
import { Alert } from 'reactstrap';

const RandomAlert = React.memo(({ alerts }) => (
    <>
        {Array.isArray(alerts) && !!alerts.length && (
            <Alert color='danger'>
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(alerts[Math.floor(Math.random() * alerts.length)].text),
                    }}
                />
            </Alert>
        )}
    </>
));

export default RandomAlert;
