import axios from 'axios';

class AxiosTokenSource {
    static cancelTokenSource = axios.CancelToken.source();

    static get token() {
        return AxiosTokenSource.cancelTokenSource.token;
    }

    static cancel = () => {
        AxiosTokenSource.cancelTokenSource.cancel();
        AxiosTokenSource.cancelTokenSource = axios.CancelToken.source();
    };
}

export default AxiosTokenSource;
