import React from 'react';

import { BUTTONS_TYPES } from 'src/config/constants';
import GoogleBtnAuthWrapper from 'src/config/GoogleBtnAuthWrapper';
import { btn_text } from 'src/config/translation';
import { btn_header_audit } from 'src/styles/Btns.module.scss';

export default React.memo(({ lang, hidden = false }) => (
    <GoogleBtnAuthWrapper>
        {!hidden ? (
            <button className={btn_header_audit}>
                {lang in btn_text[BUTTONS_TYPES.AUDIT] && btn_text[BUTTONS_TYPES.AUDIT][lang]}
            </button>
        ) : (
            <></>
        )}
    </GoogleBtnAuthWrapper>
));
