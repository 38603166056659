import React from 'react';

import { Link } from 'react-router-dom';
import { breadcrumbs_panel, breadcrumbs_panel_item } from 'src/styles/Common.module.scss';

const BreadCrumb = React.memo(({ name, url }) => (
    <Link to={url} className={breadcrumbs_panel_item}>
        {name}
    </Link>
));

const BreadCrumbs = React.memo(({ breadcrumbs }) => {
    if (!Array.isArray(breadcrumbs) || breadcrumbs.length === 0) return <></>;

    return (
        <div className={breadcrumbs_panel}>
            {Array.isArray(breadcrumbs) &&
                breadcrumbs.map((breadcrumb) => (
                    <BreadCrumb
                        key={breadcrumb.url}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...breadcrumb}
                    />
                ))}
        </div>
    );
});

export default BreadCrumbs;
