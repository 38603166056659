import React from 'react';

import { Navbar } from 'reactstrap';
import { Navigation } from 'src/components/layouts/base/Nav';
import { navbar_main, navbar_main_link } from 'src/styles/Common.module.scss';

export default React.memo(({ pages, lang }) => {
    return (
        <Navbar className={navbar_main} light expand='md'>
            <Navigation pages={pages} lang={lang} class_navbar_link={navbar_main_link} />
        </Navbar>
    );
});
