import React from 'react';

import { Spinner } from 'reactstrap';
import { loading_indicator_absolute, loading_indicator_fixed } from 'src/styles/Common.module.scss';

export const LOADING_INDICATOR_POSITION = {
    ABSOLUTE: 'ABSOLUTE',
    FIXED: 'FIXED',
    DEFAULT: 'ABSOLUTE',
};

const LoadingIndicator = React.memo(({ position = LOADING_INDICATOR_POSITION.DEFAULT }) => (
    <div
        className={
            position === LOADING_INDICATOR_POSITION.ABSOLUTE ? loading_indicator_absolute : loading_indicator_fixed
        }>
        <Spinner type='grow' color='primary' />
    </div>
));

export default LoadingIndicator;
