/** @module config/axiosTranslatorProvider */

import React, { PureComponent } from 'react';

import axios from 'axios';
import produce from 'immer';
import moment from 'moment';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { LANGUAGES, HEADERS } from 'src/config/constants';
import { LangContext } from 'src/config/contexts';
import { setLang } from 'src/reducers/common/lang';
import { trimSlashes, createPath } from 'src/utils/pathManager';
import 'moment/locale/fr';

/**
 * Component AxiosTranslatorProvider adds /fr/ or /en/ to url
 *
 * @class
 * @property {component} children
 * @example
 * return (
 *   <AxiosTranslatorProvider>
 *      {children}
 *   </AxiosTranslatorProvider>
 * )
 */
class AxiosTranslatorProvider extends PureComponent {
    render() {
        const { children, setLang, lang } = this.props;
        return (
            <Switch>
                <Route
                    path={[createPath([LANGUAGES.EN]), createPath([LANGUAGES.FR])]}
                    render={(props) => {
                        const {
                            match: { url },
                        } = props;
                        const lang_code = trimSlashes(url);
                        const new_props = produce(props, (draft_props) => {
                            draft_props.match.params.lang_code = lang_code;
                        });
                        if (lang !== lang_code) {
                            setLang(lang_code);
                        }
                        axios.defaults.headers.common = {
                            ...axios.defaults.headers.common,
                            [HEADERS.ACCEPT_LANGUAGE]: lang_code,
                        };
                        moment.locale(lang_code);
                        document.querySelector('html').setAttribute('lang', lang_code);
                        return (
                            <LangContext.Provider value={lang_code}>
                                {React.Children.map(children, (child) => React.cloneElement(child, new_props))}
                            </LangContext.Provider>
                        );
                    }}
                />

                <Redirect to={createPath([LANGUAGES.DEFAULT])} />
            </Switch>
        );
    }
}

export default connect(({ lang: { lang } }) => ({ lang }), {
    setLang,
})(AxiosTranslatorProvider);
